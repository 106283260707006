import { RootState } from '../../rootReducer';

export const selectOpenMediaViewer = (state: RootState) =>
  state.MediaViewer.openMediaViewer;

export const selectMediaViewerItems = (state: RootState) =>
  state.MediaViewer.mediaViewerItems;

export const selectCurrentIndex = (state: RootState) =>
  state.MediaViewer.currentIndex;
