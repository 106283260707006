import { useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../../../../../../../../../redux/features/userSlice/userSlice';
import { selectUserById } from '../../../../../../../../../../../../../../redux/features/usersSlice/selectors';
// Components/ui
import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import { Audio, Document, Image, Text, Video } from './components';
import { Close } from '@mui/icons-material';
// Types
import { IMessage } from '@trii/types/dist/Common/Messages';
import { ChatType } from '@trii/types/dist/Conversations';

interface Props {
  messageReply: IMessage;
  isReplying?: boolean;
  handleClose?: () => void;
  chatType: ChatType;
}

const ReplyMessage = ({
  messageReply,
  isReplying = false,
  handleClose = () => {},
  chatType,
}: Props) => {
  const { t } = useTranslation();
  // const { handleNavigateToRepliedMessage } = useContext(messagesContext);
  const [userName, setUserName] = useState<string>('');

  const replyUser = useSelector(selectUserById(messageReply?.userId));
  const user = useSelector(selectUser);

  const getUser = async () => {
    if (user) {
      if (user.uid === messageReply?.userId) {
        setUserName(t('chat.you'));
        return;
      }
    }

    let messageUserName: string = '';

    if (chatType === ChatType.EXTERNAL) {
    } else {
      messageUserName = replyUser?.name;
    }
    // const newUser = await getUserInfo(messageReply.userId);
    setUserName(messageUserName);
  };

  useEffect(() => {
    if (messageReply) {
      getUser();
    }
  }, [messageReply]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        // onClick={() => handleNavigateToRepliedMessage(messageReply.id)}
        sx={{
          cursor: 'pointer',
          bgcolor: 'rgba(0, 0, 0, 0.05)',
          borderRadius: '0.5rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0.5rem 0',
          padding: '5px 0px 5px 5px',
          width: '100%',
          height: 'max-content',
          maxHeight: '5rem',
          overflow: 'hidden',
          userSelect: 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 'max-content',
            padding: '0 5px',
          }}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: '1rem',
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-word',
            }}
          >
            {userName}
          </Typography>

          {messageReply && messageReply?.text && (
            <Text text={messageReply.text.body} />
          )}
          {messageReply && messageReply?.audio && (
            <Audio messageId={messageReply.id} />
          )}
          {messageReply &&
            messageReply?.images &&
            messageReply.images.length > 0 && (
              <Image image={messageReply.images[0]} />
            )}
          {messageReply &&
            messageReply?.videos &&
            messageReply.videos.length > 0 && (
              <Video video={messageReply.videos[0]} />
            )}
          {messageReply &&
            messageReply?.documents &&
            messageReply.documents.length > 0 && (
              <Document documents={messageReply.documents} />
            )}
        </Box>
        {((messageReply &&
          messageReply?.images &&
          messageReply?.images.length > 0) ||
          (messageReply &&
            messageReply?.videos &&
            messageReply?.videos.length > 0)) && (
          <CardMedia
            component={messageReply?.images.length > 0 ? 'img' : 'video'}
            src={
              messageReply?.images.length > 0
                ? messageReply?.images[0]?.url
                : messageReply?.videos[0]?.url
            }
            sx={{
              width: 'auto',
              height: '5rem',
              objectFit: 'contain',
              borderRadius: '5px',
            }}
          />
        )}
      </Box>
      {isReplying && (
        <IconButton onClick={handleClose} sx={{ color: 'text.disabled' }}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
};

export default ReplyMessage;
