import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import { MessageImage } from '@trii/types/dist/Common/Messages';

export type MediaViewerState = {
  openMediaViewer: boolean;
  mediaViewerItems: MessageImage[];
  currentIndex: number;
};

const initialState: MediaViewerState = {
  openMediaViewer: false,
  mediaViewerItems: [],
  currentIndex: 0,
};

const mediaViewerSlice = createSlice({
  name: 'mediaViewer',
  initialState,
  reducers: {
    setOpenMediaViewer(state, action: PayloadAction<boolean>) {
      state.openMediaViewer = action.payload;
    },
    setMediaViewerItems(state, action: PayloadAction<MessageImage[]>) {
      state.mediaViewerItems = action.payload;
    },
    setCurrentIndex(state, action: PayloadAction<number>) {
      state.currentIndex = action.payload;
    },
    resetMediaViewer(state) {
      state.openMediaViewer = false;
      state.mediaViewerItems = [];
      state.currentIndex = 0;
    },
  },
});

export const {
  setOpenMediaViewer,
  setMediaViewerItems,
  setCurrentIndex,
  resetMediaViewer,
} = mediaViewerSlice.actions;

export default mediaViewerSlice.reducer;
