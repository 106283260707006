import { useState, useEffect, forwardRef } from 'react';
// Components/ui
import {
  Box,
  CardMedia,
  Dialog,
  DialogContent,
  Fade,
  IconButton,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
// Components
import { Image, PrintButton, Video } from './components';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import {
  selectCurrentIndex,
  selectMediaViewerItems,
  selectOpenMediaViewer,
} from '../../../../redux/features/mediaViewerSlice/selectors';
import {
  setCurrentIndex,
  setOpenMediaViewer,
} from '../../../../redux/features/mediaViewerSlice/mediaViewerSlice';
import { getFileType } from '../../../../redux/features/messagesSlice/functions';

const Transition = forwardRef(function Transition(
  props: any,
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'transparent',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  right: '15px',
  zIndex: 1,
  color: theme.palette.primary.contrastText,
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const MediaBox = styled(Box)(({ theme }) => ({
  maxHeight: '80vh',
  maxWidth: '80vw',
  overflow: 'hidden',
  width: 'auto',
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
}));

const CaptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textAlign: 'center',
  marginTop: theme.spacing(1),
}));

const ImageViewer = () => {
  const dispatch = useAppDispatch();

  const openMediaViewer = useSelector(selectOpenMediaViewer);
  const mediaViewerItems = useSelector(selectMediaViewerItems);
  const currentIndex = useSelector(selectCurrentIndex);

  const [url, setUrl] = useState<string>('');
  const [caption, setCaption] = useState<string>('');
  const [type, setType] = useState<string>('');

  useEffect(() => {
    if (mediaViewerItems && mediaViewerItems.length > 0) {
      const { url, caption, mimeType } = mediaViewerItems[currentIndex];
      const type = getFileType(mimeType) || 'image';
      setUrl(url);
      setCaption(caption);
      setType(type);
    }
  }, [mediaViewerItems, currentIndex]);

  const handleClose = () => {
    dispatch(setOpenMediaViewer(false));
  };

  const handleNext = () => {
    dispatch(setCurrentIndex((currentIndex + 1) % mediaViewerItems?.length));
  };

  const handlePrevious = () => {
    dispatch(
      setCurrentIndex(
        currentIndex === 0 ? mediaViewerItems?.length - 1 : currentIndex - 1
      )
    );
  };

  return (
    <StyledDialog
      open={openMediaViewer}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth="lg"
      fullWidth
      fullScreen
    >
      <StyledDialogContent>
        <PrintButton imageUrl={url} />
        <StyledIconButton color="inherit" onClick={handleClose} size="large">
          <CloseIcon sx={{ fontSize: 28 }} />
        </StyledIconButton>
        <NavigationButton onClick={handlePrevious} size="large">
          <ChevronLeftIcon sx={{ fontSize: 28 }} />
        </NavigationButton>
        <Box>
          <MediaBox>
            {type === 'image' ? <Image url={url} /> : <Video url={url} />}
          </MediaBox>
          <CaptionTypography variant="body2">{caption}</CaptionTypography>
        </Box>
        <NavigationButton onClick={handleNext} size="large">
          <ChevronRightIcon sx={{ fontSize: 28 }} />
        </NavigationButton>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ImageViewer;
