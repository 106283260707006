// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Chip } from '@mui/material';

interface Props {
  day: string;
  today: string;
  yesterday: string;
}

const Date = ({ day, today, yesterday }: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pr: 1,
        pl: 1,
        marginBottom: '15px',
        // position: 'sticky',
        // top: '1rem',
        // zIndex: 10,
      }}
    >
      <Box
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Chip
          label={
            day === today
              ? t('chat.today').toUpperCase()
              : day === yesterday
              ? t('chat.yesterday').toUpperCase()
              : day
          }
          sx={{
            backgroundColor:
              //@ts-ignore
              (theme) => theme.palette.background.dropdownMenu,
            fontWeight: 'normal',
            fontSize: '1em',
            color: (theme) => theme.palette.text.secondary,
            borderRadius: '0.3rem',
            height: 'max-content',
            padding: '.2rem',
          }}
        />
      </Box>
    </Box>
  );
};

export default Date;
