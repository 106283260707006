import { useEffect, useState } from 'react';
// Components/ui
import { Typography, Avatar, Box } from '@mui/material';
import { styled } from '@mui/system';
// Types
import {
  IMessage,
  MessageImage,
  MessageVideo,
} from '@trii/types/dist/Common/Messages';
import { ChatType } from '@trii/types/dist/Conversations';
// Redux
import { useSelector } from 'react-redux';
import {
  getUser,
  selectUser,
} from '../../../../../../../../../../redux/features/userSlice/userSlice';
import { selectUsersList } from '../../../../../../../../../../redux/features/usersSlice/selectors';
import { useTranslation } from 'react-i18next';
import { Body, Header } from './components';
import { UserTrii } from '@trii/types/dist/Users';
import { useAppDispatch } from '../../../../../../../../../../hooks/useAppDispatch';
import { MediaFile } from '../../../../../../../../../../redux/features/messagesSlice/types';
import { setFilesDoneLoading } from '../../../../../../../../../../redux/features/chatsSlice/chatsSlice';
import useSASAvatarURL from '../../../../../../../../../../hooks/useSASAvatarURL';
import { ClientConversation } from '../../../../../../../../../../redux/features/chatsSlice/types';

interface Props {
  data: IMessage;
  chatType: ChatType;
  conversationId: string;
  messageLoading: string[];
  contactInfo: ClientConversation;
}

const REGEX_BASE64 = /^data:([a-z]+\/[a-z0-9-+.]+);base64/;

const MessageContainer = styled(Box)({
  display: 'flex',
  marginBottom: '0.6rem',
  gap: 9,
});

const MessageAvatar = styled(Avatar)({
  width: 26,
  height: 26,
});

const MessageContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const Message = ({ data, chatType, conversationId, messageLoading, contactInfo }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [messageUsername, setMessageUsername] = useState<string>('');
  const [files, setFiles] = useState<MediaFile[]>([]);
  const [audioUrl, setAudioUrl] = useState<string>(null);

  const user = useSelector(selectUser);
  const users = useSelector(selectUsersList);

  const {
    text,
    audio,
    id,
    from,
    timestamp,
    documents,
    locations,
    stickers,
    images,
    videos,
    userId,
    messageReference,
  } = data;

  async function getSas() {
    const response = await dispatch(getUser());
    const userTrii = response.payload as UserTrii;
    const { storageAzureSAS } = userTrii;

    return storageAzureSAS.sas;
  }

  // Set message username
  useEffect(() => {
    if (from === user.uid || userId === user.uid) {
      setMessageUsername(t('chat.you'));
    } else {
      if (chatType === ChatType.DIRECT || chatType === ChatType.GROUP) {
        setMessageUsername(
          users.find((user) => user.id === from)?.name || 'Username not found'
        );
      } else if (chatType === ChatType.EXTERNAL) {
        setMessageUsername(
          data.remoteContactData?.name !== ''
            ? data.remoteContactData?.name
            : data.remoteContactData?.address
        );
      }
    }
  }, []);

  useEffect(() => {
    // This useEffect hook is used to fetch the user's Azure Storage Access Signature (SAS) and update the URLs of images, videos, and files with the SAS.
    function addSasToMediaUrls(
      mediaArray: (MessageImage | MessageVideo | MediaFile)[] | null,
      sas: string
    ) {
      if (!mediaArray) {
        return [];
      }

      return mediaArray.map((mediaItem) => {
        if (mediaItem.url.startsWith('https://')) {
          return {
            ...mediaItem,
            url: mediaItem.url + sas,
          };
        }
        return mediaItem;
      });
    }

    const setMedia = async () => {
      const expiredAtDate = new Date(user.storageAzureSAS.expireAt);
      let sas = '';

      if (expiredAtDate < new Date()) {
        sas = await getSas();
      } else {
        sas = user.storageAzureSAS.sas;
      }
      const imagesWithAccess = addSasToMediaUrls(images, sas);
      const videosWithAccess = addSasToMediaUrls(videos, sas);
      const filesWithAccess = addSasToMediaUrls(files, sas);

      if (imagesWithAccess?.length > 0 && videosWithAccess?.length > 0) {
        setFiles([...imagesWithAccess, ...videosWithAccess]);
      } else if (imagesWithAccess?.length > 0) {
        setFiles([...filesWithAccess, ...imagesWithAccess]);
      } else if (videosWithAccess?.length > 0) {
        setFiles([...filesWithAccess, ...videosWithAccess]);
      }
    };

    setMedia();
    dispatch(setFilesDoneLoading({ doneLoading: true, chatId: conversationId }));
  }, []);

  // Set audio url with access
  useEffect(() => {
    async function setAudioUrlWithAccess() {
      const expiredAtDate = new Date(user.storageAzureSAS.expireAt);
      let sas = '';

      if (expiredAtDate < new Date()) {
        sas = await getSas();
      } else {
        sas = user.storageAzureSAS.sas;
      }

      setAudioUrl(audio.url + sas);
    }

    if (audio && audio.url && !REGEX_BASE64.test(audio.url)) {
      setAudioUrlWithAccess();
    }
  }, [audio]);
  const imageURLWithAccess = useSASAvatarURL(contactInfo.chatImage);
  return (
    <MessageContainer>
      <MessageAvatar 
      alt={contactInfo.chatName}
      src={imageURLWithAccess}
      sx={{
        width: 26,
        height: 26,
      }}>
        {contactInfo.chatName?.charAt(0).toUpperCase()}

      </MessageAvatar>

      <MessageContent>
        <Header timestamp={timestamp} username={messageUsername} />
        <Body
          id={id}
          text={text}
          locations={locations}
          audio={audio}
          audioUrl={audioUrl}
          documents={documents}
          files={files}
          stickers={stickers}
          messageReference={messageReference}
          chatType={chatType}
          messageLoading={messageLoading}
        />
      </MessageContent>
    </MessageContainer>
  );
};

export default Message;
