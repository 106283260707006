import { createSelector } from '@reduxjs/toolkit';
// Types
import { RootState } from '../../rootReducer';

const chatsState = (state: RootState) => state.Chats;

export const selectChats = createSelector(chatsState, (chats) => chats.chats);
export const selectChatById = (chatId: string) =>
  createSelector(selectChats, (chats) => chats[chatId]);
export const selectOpenChats = createSelector(selectChats, (chats) =>
  Object.values(chats).filter((chat) => chat.open)
);
