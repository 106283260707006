import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
// Components/ui
import { IconButton } from '@mui/material';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import './print.css';

type PrintButtonProps = {
  imageUrl: string;
};

const PrintButton: React.FC<PrintButtonProps> = ({ imageUrl }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const reactToPrintFn = useReactToPrint({ contentRef });

  return (
    <>
      <IconButton
        color="inherit"
        // onClick={reactToPrintFn}
        size="large"
        sx={{
          position: 'absolute',
          top: '10px',
          right: '60px',
          zIndex: 1,
          color: 'primary.contrastText',
        }}
      >
        <LocalPrintshopIcon
          sx={{
            fontSize: 28,
          }}
        />
      </IconButton>
      <div style={{ display: 'none' }}>
        <div id="print-content">
          <div ref={contentRef} />
        </div>
      </div>
    </>
  );
};

export default PrintButton;
