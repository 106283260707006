// Components/ui
import { ChatType } from '@trii/types/dist/Conversations';
import { UserInfo, Buttons } from './components';

const styles = {
  container: {
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 0.5rem',
    alignItems: 'center',
  },
};

interface Props {
  chatId: string;
  userName: string;
  chatType: ChatType;
  channelType: number;
  chatImage: string;
}

const Header = ({ chatId, userName, chatType, channelType,chatImage }: Props) => {
  return (
    <div style={styles.container}>
      <UserInfo userName={userName} channelType={channelType} chatImage={chatImage}/>
      <Buttons chatId={chatId} chatType={chatType} />
    </div>
  );
};

export default Header;
