import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// Components/ui
import { Box, Slide, Typography } from '@mui/material';
import { style } from './style';
import { AvatarChatItem } from './components';
import { useSelector } from 'react-redux';
import { selectChats } from '../../../../../../redux/features/chatsSlice/selectors';

const PanelButton = () => {
  const [hovering, setHovering] = useState(false);

  const chats = useSelector(selectChats);

  const slideWidth = 300;

  return (
    <Box
      sx={style.container}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Box sx={{ ...style.button, width: hovering ? slideWidth : '26.78px' }}>
        <Box
          sx={{
            ...style.textContainer,
          }}
        >
          <Typography color={'white'}>Chats</Typography>
        </Box>
      </Box>
      <Slide direction="left" in={hovering} mountOnEnter unmountOnExit>
        <Box sx={style.drawer}>
          {/* {chats.map((data) => (
            <AvatarChatItem data={data} key={data.id} />
          ))} */}
          {Object.keys(chats).map((chatId) => (
            <AvatarChatItem chatId={chatId} key={chatId} />
          ))}
          {/* {Object.keys(openChats).map((chatId) => (
            <div key={chatId}>
              <ChatWindow chatId={chatId} />
              <button onClick={() => handleCloseChat(chatId)}>Close Chat</button>
            </div>
          ))} */}
        </Box>
      </Slide>
    </Box>
  );
};

export default PanelButton;
