import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import { AudioRecordState } from './types';

const initialState: AudioRecordState = {
  uploadAudiosRejected: [],
  recordAudioStream: null,
};

const audioRecordSlice = createSlice({
  name: 'audioRecord',
  initialState,
  reducers: {
    setRecordAudioStream: (state, action: PayloadAction<MediaStream | null>) => {
      state.recordAudioStream = action.payload;
    },
    addUploadAudioRejected: (state, action: PayloadAction<string>) => {
      state.uploadAudiosRejected.push(action.payload);
    },
    removeUploadAudioRejected: (state, action: PayloadAction<string>) => {
      state.uploadAudiosRejected = state.uploadAudiosRejected.filter(
        (audioId) => audioId !== action.payload
      );
    },
  },
});

export const {
  setRecordAudioStream,
  addUploadAudioRejected,
  removeUploadAudioRejected,
} = audioRecordSlice.actions;

export default audioRecordSlice.reducer;
