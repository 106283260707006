import { Socket } from 'socket.io-client';

import 'react-toastify/dist/ReactToastify.css';

import { useContext, useEffect, useRef } from 'react';
// Context
import { socketContext } from '../../context/WebSocketProvider/SocketProvider';
import SipContext from '../../context/Sip/SipContext';
// Components/ui
import {
  Calls,
  Chats,
  ImageViewer,
  SessionMessageModal,
  UserStatus,
} from './components';
import { Box } from '@mui/material';
import ModuleIcons from './components/ModuleIcons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
// Redux
import {
  fetchUsers,
  updateUserPresence,
} from '../../redux/features/usersSlice/usersSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { toggleTheme } from '../../redux/features/themeSlice/themeSlice';
import { useSelector } from 'react-redux';
import {
  addNewChat,
  fetchChats,
  openChatItemWindow,
  removeChatById,
} from '../../redux/features/chatsSlice/chatsSlice';
import {
  selectHaveUrlsBeenSet,
  setSpaceInfo,
  setSpaceUrls,
} from '../../redux/features/spaceSlice/spaceSlice';
import { setUser } from '../../redux/features/userSlice/userSlice';
import {
  selectExpirationState,
  setExpirationState,
} from '../../redux/features/sessionSlice/sessionSlice';
import { selectUsersFetchStatus } from '../../redux/features/usersSlice/selectors';
// Types
import RootProps from '../../types/RootProps';
import { IMessage, MessageType } from '@trii/types/dist/Common/Messages';
import { IConversation } from '@trii/types/dist/Conversations';
import { UserPresence } from '@trii/types/dist/Users';
// Hooks
import { useModuleEvent } from './utils/moduleEvents';
import { ModuleIconsContext } from '../../context/ModuleIcons/ModuleIconsContext';
// Db
import { chatsDbWorker } from '../../db/chatDb';
import { conversationsDbWorker } from '../../db/conversationsDb';
// Utils
import chatSliceService from '../../redux/features/chatsSlice/service';
import {
  getFetchMessagesData,
  getMessagesRelationId,
} from '../../redux/features/messagesSlice/functions';
import { GetMessagesData } from '../../redux/features/messagesSlice/types';
import {
  fetchMessages,
  setFetchMessagesLoadingState,
} from '../../redux/features/messagesSlice/messagesSlice';
import { selectChats } from '../../redux/features/chatsSlice/selectors';

interface Props {
  rootProps: RootProps;
}
const Footer = ({ rootProps }: Props) => {
  const dispatch = useAppDispatch();
  const { doesUrlContainModule, getModuleNameForEvent } = useModuleEvent();

  const {
    connectionStatus,
    emitEvent,
    subscribeEvent,
    sessionMessageModalOpen,
    setSessionMessageModalOpen,
    socketConnectionState,
    listenToAllEvents,
  } = useContext(socketContext);
  const { handleLocalStorageChange } = useContext(ModuleIconsContext);
  const {
    userAgent,
    handleMakeCallFromExternal,
    drawer: { handleOpenDrawer },
  } = useContext(SipContext);

  const usersFetchStatus = useSelector(selectUsersFetchStatus);
  const haveUrlsBeenSet = useSelector(selectHaveUrlsBeenSet);
  const expirationState = useSelector(selectExpirationState);
  const expirationStateRef = useRef(expirationState);
  const chats = useSelector(selectChats);

  const keepAliveTimeout = useRef(null);
  const chatsRef = useRef(chats);

  const sendPresence = () => {
    if (keepAliveTimeout.current) {
      return;
    }
    emitEvent('keepAlive');
    emitEvent('keepPresence');

    keepAliveTimeout.current = setTimeout(() => {
      keepAliveTimeout.current = null;
    }, 30000);

    // Only close the modal in case the session is still valid and maxium sessions modal is not open
    if (
      expirationStateRef.current === 'valid' &&
      sessionMessageModalOpen.message !== 'modal.messageMaximumSessions'
    )
      setSessionMessageModalOpen({
        open: false,
        message: '',
      });
  };

  useEffect(() => {
    if (!haveUrlsBeenSet) return;

    dispatch(fetchUsers());
  }, [haveUrlsBeenSet]);

  useEffect(() => {
    if (!socketConnectionState || usersFetchStatus !== 'succeeded') return;

    const handleUsersPresence = (data: UserPresence[]) => {
      data.forEach((userPresence) => {
        dispatch(updateUserPresence(userPresence));
      });
    };

    emitEvent('usersPresence', null, handleUsersPresence);
  }, [socketConnectionState, usersFetchStatus]);

  useEffect(() => {
    window.addEventListener('mousemove', sendPresence);
    window.addEventListener('keydown', sendPresence);

    return () => {
      clearTimeout(keepAliveTimeout.current);
      window.removeEventListener('mousemove', sendPresence);
      window.removeEventListener('keydown', sendPresence);
    };
  }, []);

  useEffect(() => {
    if (rootProps.userInfo && rootProps.spaceInfo) {
      const { userInfo, spaceInfo } = rootProps;
      dispatch(toggleTheme(userInfo.theme));
      dispatch(setUser(userInfo));
      dispatch(setSpaceInfo(spaceInfo));
      dispatch(setSpaceUrls(spaceInfo.id));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchChats());
  }, []);

  useEffect(() => {
    // Stop SIP connection when session limit modal is open (websocket connection is closed)
    if (expirationState === 'expired') {
      userAgent.stopUa();

      if (typeof window !== 'undefined' && (window as any).socketConnection) {
        let socket: Socket = (window as any).socketConnection;

        socket.disconnect();
      }

      clearTimeout(keepAliveTimeout.current);
      window.removeEventListener('mousemove', sendPresence);
      window.removeEventListener('keydown', sendPresence);
    }
  }, [expirationState]);

  useEffect(() => {
    if (!socketConnectionState) return;

    subscribeEvent('sessionExpiredAt', () => {
      setSessionMessageModalOpen({
        open: true,
        message: 'modal.messageSessionExpiredWarning',
      });
    });

    subscribeEvent('sessionExpired', () => {
      setSessionMessageModalOpen({
        open: true,
        message: 'modal.messageSessionExpired',
      });
      dispatch(setExpirationState('expired'));
    });

    subscribeEvent('user_presence', (userPresence: UserPresence) => {
      dispatch(updateUserPresence(userPresence));
    });

    subscribeEvent('keepAlive', () => {
      sendPresence();
    });

    subscribeEvent('message_new', async (data: IMessage) => {
      if (data.type === MessageType.CHAT) {
        await chatsDbWorker.postMessage({
          action: 'setMessage',
          data: data,
        });
      } else {
        await conversationsDbWorker.postMessage({
          action: 'setMessage',
          data: data,
        });
      }
    });

    subscribeEvent('floatingwindow_add', async (data: IConversation) => {
      let chat = chatSliceService.generateClientConversation(data);

      const id = getMessagesRelationId(chat);
      const fetchMessagesData: GetMessagesData = getFetchMessagesData(
        chat.type,
        chat.id,
        id
      );

      chat.open = true;

      dispatch(addNewChat(chat));

      dispatch(setFetchMessagesLoadingState(id));
      dispatch(fetchMessages(fetchMessagesData));
    });

    subscribeEvent(
      'floatingwindow_remove',
      (data: { conversationId: string; chatId: string }) => {
        if (data.conversationId) {
          dispatch(removeChatById(data.conversationId));
        } else {
          dispatch(removeChatById(data.chatId));
        }
      }
    );

    listenToAllEvents((eventName, ...args) => {
      if (eventName) {
        const moduleName = getModuleNameForEvent(eventName);

        if (moduleName) {
          if (
            !doesUrlContainModule(eventName) || // Si no es el URL perteneciente al modulo actual
            (document.visibilityState !== 'visible' && // Si la pestaña no esta visible
              doesUrlContainModule(eventName)) // Si es el URL perteneciente al modulo actual
          ) {
            localStorage.setItem(`newevents_${moduleName}`, 'true');
            handleLocalStorageChange(`newevents_${moduleName}`, 'true');
          } else {
            setTimeout(() => {
              localStorage.setItem(`newevents_${moduleName}`, 'false');
              handleLocalStorageChange(`newevents_${moduleName}`, 'false');
            }, 500);
          }
        }
      }
    });
  }, [socketConnectionState]);

  useEffect(() => {
    const extractModuleName = (url: string) => {
      const match = url.match(/\/a\/([^\/]+)/);
      return match ? match[1] : null;
    };

    const checkModuleEvents = () => {
      const moduleName = extractModuleName(location.pathname);
      if (moduleName) {
        const key = `newevents_${moduleName}`;
        if (localStorage.getItem(key) === 'true') {
          localStorage.setItem(key, 'false');
          handleLocalStorageChange(key, 'false');
        }
      }
    };

    checkModuleEvents(); // Check on initial render

    // Check on URL change
    return () => {
      checkModuleEvents();
    };
  }, [location.pathname]);

  useEffect(() => {
    expirationStateRef.current = expirationState;
  }, [expirationState]);

  useEffect(() => {
    const handleCallContactFromProject = (event) => {
      const { phoneAddress } = event.detail;

      if (phoneAddress) {
        handleOpenDrawer();
        handleMakeCallFromExternal(phoneAddress);
      }
    };

    const handleOpenChatWindowEvent = (event) => {
      if (event.data.type === 'openChatWindow') {
        const chatWindowId =
          event.data.data.conversationId !== ''
            ? event.data.data.conversationId
            : event.data.data.chatId;

        const chat = chatsRef.current[chatWindowId];
        if (chat) dispatch(openChatItemWindow(chat));
      }
    };

    window.addEventListener('call-contact-header', handleCallContactFromProject);
    window.addEventListener(
      'call-contact-conversations',
      handleCallContactFromProject
    );
    window.addEventListener('message', handleOpenChatWindowEvent);

    return () => {
      window.removeEventListener(
        'call-contact-header',
        handleCallContactFromProject
      );
      window.removeEventListener(
        'call-contact-conversations',
        handleCallContactFromProject
      );
      window.removeEventListener('message', handleOpenChatWindowEvent);
    };
  }, []);

  useEffect(() => {
    chatsRef.current = chats;
  }, [chats]);

  library.add(fas);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        position: 'fixed',
        backgroundColor: 'background.default',
        padding: (theme) => theme.spacing(0, 2),
        boxShadow: '0 -3px 6px 0 rgba(0,0,0,.25)',
        flex: '0 0 auto',
        // height: '1.3rem',
        height: '1.5rem',
        borderBottom: '1px solid transparent',
        overflow: 'visible',
        paddingLeft: '15px',
        bottom: '0',
        zIndex: 11,
        color: 'text.primary',
      }}
    >
      <UserStatus connectionStatus={connectionStatus} />
      <ModuleIcons />
      <Chats />
      <Calls />
      <SessionMessageModal
        open={sessionMessageModalOpen.open}
        message={sessionMessageModalOpen.message}
      />
      <ImageViewer />
    </Box>
  );
};

export default Footer;
