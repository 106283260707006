// Components/ui
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

interface Props {
  text: string;
}

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '2.5rem',
  overflow: 'hidden',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
  position: 'relative',
});

const AbsoluteBox = styled(Box)({
  position: 'absolute',
  top: '0%',
  display: 'flex',
  alignItems: 'center',
  gap: 1,
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '.8rem',
}));

const Text = ({ text }: Props) => {
  return (
    <StyledBox>
      <AbsoluteBox>
        <StyledTypography>{text}</StyledTypography>
      </AbsoluteBox>
    </StyledBox>
  );
};

export default Text;
