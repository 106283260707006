import { useState, useRef } from 'react';
import { AudioMessagesContext } from './AudioMessagesContext';
// Types
import { AudioMessagesProviderProps } from './types/AudioMessagesProviderProps';
import { Duration } from './types/Duration';
// Hooks
import useMediaRecorder from '../../hooks/useMediaRecorder';

export const AudioMessagesProvider = ({
  children,
  recordAudioStream,
  endRecordAudioMode,
}: AudioMessagesProviderProps) => {
  const {
    isRecording,
    recordedAudioURL,
    startRecording,
    pauseRecording,
    recordedAudioBase64,
    isPaused,
  } = useMediaRecorder(recordAudioStream);
  const waveformRef = useRef<any | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState('0:00');
  const [durations, setDurations] = useState<Duration[]>();

  const playAudio = () => {
    if (recordedAudioURL) {
      setIsPlaying(true);
      waveformRef.current.playPause();
    }
  };
  const stopAudio = () => {
    if (recordedAudioURL) {
      setIsPlaying(false);
      waveformRef.current.playPause();
    }
  };
  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${('0' + seconds).slice(-2)}`;
  };
  const handleWaveformClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const clickPositionX =
      event.clientX - waveformRef.current.container.getBoundingClientRect().left;
    const waveformWidth = waveformRef.current.container.clientWidth;
    const clickPercentage = clickPositionX / waveformWidth;
    const seekFraction = Math.min(1, Math.max(0, clickPercentage));
    const seekTime = seekFraction * waveformRef.current.getDuration();

    waveformRef.current.seekTo(seekFraction);
    setCurrentTime(formatTime(seekTime));
  };
  const handleDeleteRecordedAudio = () => {
    if (isRecording) {
      pauseRecording();
      endRecordAudioMode();
      return;
    }
    if (recordedAudioURL) {
      URL.revokeObjectURL(recordedAudioURL);
      endRecordAudioMode();
      return;
    }
  };

  return (
    <AudioMessagesContext.Provider
      value={{
        isRecording,
        startRecording,
        pauseRecording,
        isPlaying,
        currentTime,
        durations,
        setDurations,
        playAudio,
        stopAudio,
        handleWaveformClick,
        waveformRef,
        recordedAudioURL,
        formatTime,
        setIsPlaying,
        setCurrentTime,
        handleDeleteRecordedAudio,
        recordedAudioBase64,
        isPaused,
      }}
    >
      {children}
    </AudioMessagesContext.Provider>
  );
};
