import { createSelector } from '@reduxjs/toolkit';
// Types
import { RootState } from '../../rootReducer';

const selectUsers = (state: RootState) => state.Users;

export const selectUsersStatus = createSelector(
  selectUsers,
  (users) => users.status
);
export const selectUsersList = createSelector(selectUsers, (users) => users.users);
export const selectUsersFetchStatus = createSelector(
  selectUsers,
  (users) => users.status.fetch
);
export const selectUserById = (userId: string) =>
  createSelector(selectUsersList, (users) =>
    users.find((user) => user.id === userId)
  );
