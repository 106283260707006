import { useState, useEffect, useRef, useCallback } from 'react';
// Redux
import { useAppDispatch } from './useAppDispatch';
import { setFilesDoneLoading } from '../redux/features/chatsSlice/chatsSlice';

export const useAutoScroll = <T extends HTMLElement>(
  dependency: any[],
  filesDoneLoading: boolean,
  chatId: string
) => {
  const [isAtBottom, setIsAtBottom] = useState(true);
  const containerRef = useRef<T | null>(null);
  const dispatch = useAppDispatch();

  const scrollToBottom = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, []);

  const handleScroll = useCallback(() => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    const isUserAtBottom = scrollTop + clientHeight === scrollHeight;
    setIsAtBottom(isUserAtBottom);
  }, []);

  useEffect(() => {
    if (isAtBottom) {
      scrollToBottom();
    }
  }, [isAtBottom, scrollToBottom, dependency]);

  // New effect to handle filesDoneLoading
  useEffect(() => {
    if (filesDoneLoading) {
      scrollToBottom();
      // After scrolling, dispatch the action to set filesDoneLoading to false
      dispatch(setFilesDoneLoading({ chatId, doneLoading: false }));
    }
  }, [filesDoneLoading, scrollToBottom, dispatch, chatId]);

  return {
    containerRef,
    handleScroll,
    scrollToBottom,
  };
};
