// Components/ui
import { Avatar, Badge, Box, Typography } from '@mui/material';
import getImage from '../functions/getImage';
import { ReactNode, useState } from 'react';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import useSASAvatarURL from '../../../../../../../../../../hooks/useSASAvatarURL';

interface Props {
  userName: string;
  channelType: ChannelType;
  chatImage: string;
}

const UserInfo = ({ userName, channelType, chatImage }: Props) => {
  const imageURLWithAccess = useSASAvatarURL(chatImage);

  const image = getImage(channelType);
  const icon: ReactNode = image.icon;

  return (
    <Box
      style={{
        display: 'flex',
        gap: 9,
        justifyItems: 'center',
        alignItems: 'center',
        maxWidth: '80%'
      }}
    >
   
      <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={icon}
            sx={{
              "& .MuiBadge-badge": {
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.dropdownMenu,
                width: 18,
                height: 18,
                borderRadius: "50%",
              },
              "& .MuiBadge-badge svg": {
                width: "0.7em",
                height: "0.7em",
              },
            }}
          >

      <Avatar
        alt={userName}
        src={imageURLWithAccess}
        sx={{
          width: 26,
          height: 26,
        }}
      >
        {userName?.charAt(0).toUpperCase()}
      </Avatar>
          </Badge>
      <Typography variant="body2" color="text.primary" sx={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {userName}
      </Typography>
    </Box>
  );
};

export default UserInfo;
