// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
// Icons
import { Image as ImageIcon } from '@mui/icons-material';
// Types
import { MessageImage } from '@trii/types/dist/Common/Messages';

interface ImageProps {
  image: MessageImage;
}

const ImageContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '1rem',
  gap: 1,
});

const StyledImageIcon = styled(ImageIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '1rem',
  marginTop: '.2rem',
}));

const TextContainer = styled(Box)({
  display: 'flex',
  alignItems: 'baseline',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '.8rem',
}));

const Image = ({ image }: ImageProps) => {
  const { t } = useTranslation();

  return (
    <ImageContainer>
      <StyledImageIcon />
      <TextContainer>
        <StyledTypography>
          {image?.caption || t('conversations.message.image')}
        </StyledTypography>
      </TextContainer>
    </ImageContainer>
  );
};

export default Image;
