import { useEffect, useState } from 'react';
// Components/ui
import { Box, Typography } from '@mui/material';
// Icons
import { Videocam } from '@mui/icons-material';
// Types
import { MessageVideo } from '@trii/types/dist/Common/Messages';

interface VideoProps {
  video: MessageVideo;
}

const Video = ({ video }: VideoProps) => {
  const [duration, setDuration] = useState<string>('--:--');

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${('0' + seconds).slice(-2)}`;
  };

  useEffect(() => {
    const videoElement = document.createElement('video');
    videoElement.src = video.url;
    videoElement.onloadedmetadata = () => {
      const time = formatTime(videoElement.duration);
      setDuration(time);
    };
  }, [video.url]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '1rem',
        gap: 1,
      }}
    >
      <Videocam
        sx={{
          color: (theme) => theme.palette.text.disabled,
          fontSize: '1rem',
          marginTop: '.2rem',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.disabled,
            fontSize: '.8rem',
          }}
        >
          {video.caption || duration}
        </Typography>
      </Box>
    </Box>
  );
};

export default Video;
