import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import initRequestData from '../../functions/initRequestData';
import { UserSliceState } from './types/UserSliceState';
// Types
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { RootState } from '../../store';
import { UserTrii } from '@trii/types/dist/Users';
// Service
import userSliceService from './userSliceService';

const initialState: UserSliceState = { user: null };

export const getUser = createAsyncThunk<UserTrii, undefined, { state: RootState }>(
  'User/getUser',
  async (_, { dispatch, getState }) => {
    const { jwtToken, URL_USER } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const triiUser = await userSliceService.fetchUserInfo(jwtToken, URL_USER);

    return triiUser;
  }
);

export const updateSas = createAsyncThunk(
  'contacts/updateSas',
  async (_, { dispatch }) => {
    const response = await dispatch(getUser());
    const userTrii = response.payload as UserTrii;
    const { storageAzureSAS } = userTrii;

    return storageAzureSAS;
  }
);

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserTrii>) => {
      state.user = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUser.fulfilled, (state, action: PayloadAction<UserTrii>) => {
        console.log('success fetching user: ', action.payload);
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        console.log('error fetching user: ', action.error);
      });
  },
});
// Actions
export const { setUser } = userSlice.actions;

// Selectors
export const selectUser = (state: RootState) => state.User.user;

export default userSlice.reducer;
