import WaveSurfer from 'wavesurfer.js';
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js';
export const getNewWaveSurfer = (container: string, progressColor: string) => {
  return WaveSurfer.create({
    container,
    barWidth: 3,
    barRadius: 2,
    barGap: 2,
    barHeight: 2,
    height: 17,
    progressColor,
    waveColor: '#6b7280',
    cursorColor: 'transparent',
    // autoCenter: true,
    // normalize: true,
    // hideScrollbar: true,
    // interact: false,
    plugins: [
      Hover.create({
        lineColor: '#ff0000',
        lineWidth: 2,
        labelBackground: '#555',
        labelColor: '#fff',
        labelSize: '11px',
        //
        // customStyle: {
        //   'background-color': 'transparent',
        //   border: 'none',
        //   height: '100%',
        //   width: '2rem',
        //   'margin-left': '0px',
        //   'margin-right': '0px',
        // },
      }),
    ],
  });
};
