import { useEffect, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography } from '@mui/material';
import { FileCopy } from '@mui/icons-material';
import { styled } from '@mui/system';
import { DocumentIcon } from '../../../Document/components';
// Types
import { MessageDocument } from '@trii/types/dist/Common/Messages';
import TextFileExtension from '../../../../../../../../../Footer/components/MediaFileImage/components/DocumentIcon/types/DocumentExtension';

interface DocumentProps {
  documents: MessageDocument[];
}

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '1rem',
  gap: 1,
});

const IconContainer = styled(Box)({
  width: '1rem',
  height: '1rem',
});

const TextContainer = styled(Box)({
  display: 'flex',
  alignItems: 'baseline',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '.8rem',
}));

const StyledFileCopy = styled(FileCopy)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '1rem',
}));

const Document = ({ documents }: DocumentProps) => {
  const { t } = useTranslation();

  const [extension, setExtension] = useState<TextFileExtension>('pdf');
  const [documentName, setDocumentName] = useState<string>('');

  const defaultDocumentName = `+${documents.length} ${t(
    'conversations.message.documents'
  )}`;

  useEffect(() => {
    if (documents.length === 1) {
      const document = documents[0];
      const { filename, mimeType } = document;
      setDocumentName(filename);
      if (mimeType.startsWith('image')) {
        setExtension('image');
        return;
      }
      if (mimeType.startsWith('video')) {
        setExtension('video');
        return;
      }
      const newExtension = filename.split('.').pop() as TextFileExtension;
      setExtension(newExtension);
    }
  }, [documents]);

  return (
    <Container>
      {documents.length > 1 ? (
        <StyledFileCopy />
      ) : (
        <IconContainer>
          <DocumentIcon documentType={extension} />
        </IconContainer>
      )}
      <TextContainer>
        <StyledTypography>{documentName || defaultDocumentName}</StyledTypography>
      </TextContainer>
    </Container>
  );
};

export default Document;
