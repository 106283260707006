import { useContext } from 'react';
// Context
// import { audioMessagesContext } from '../../../../features/Views/Conversations/components/components/Conversation/components/Footer/components/ChatMode/context/AudioMessagesProvider/AudioMessagesProvider';
// Components/ui
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
// Icons
import { Mic } from '@mui/icons-material';

interface AudioProps {
  messageId: string;
}

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '1rem',
  overflow: 'hidden',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
  position: 'relative',
});

const AbsoluteBox = styled(Box)({
  position: 'absolute',
  top: '0%',
  display: 'flex',
  alignItems: 'center',
  gap: 1,
});

const StyledMic = styled(Mic)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '1rem',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '.8rem',
}));

const Audio = ({ messageId }: AudioProps) => {
  // const { durations } = useContext(audioMessagesContext);
  // const duration = durations.find((duration) => duration.id === messageId)?.duration;
  const duration = '00:00';
  return (
    <Container>
      <AbsoluteBox>
        <StyledMic />
        <StyledTypography>{duration}</StyledTypography>
      </AbsoluteBox>
    </Container>
  );
};

export default Audio;
