// Types
import { RootState } from '../../rootReducer';
// Utils
import { createSelector } from '@reduxjs/toolkit';

const messagesState = (state: RootState) => state.Messages;

// export const selectMessagesById = (id: string) =>
//   createSelector(messagesState, (state) => {
//     return state.messages.find((messages) => messages.id === id)?.messages;
//   });
// export const selectMessagesFetchStatus = createSelector(
//   messagesState,
//   (state) => state.status.fetchMessages
// );
export const selectMessagesStatusById = (id: string) =>
  createSelector(messagesState, (state) => {
    return state.status.fetchMessages.find((status) => status.id === id)?.state;
  });
