import { useRef, useState, useEffect } from 'react';
// Components/ui
import { Box, IconButton, Slider, Tooltip } from '@mui/material';
import {
  Fullscreen,
  Pause,
  PictureInPictureAlt,
  PlayArrow,
  Replay10,
} from '@mui/icons-material';
import { Volume } from './components';
// Redix
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import {
  setMediaViewerItems,
  setOpenMediaViewer,
} from '../../../../../../redux/features/mediaViewerSlice/mediaViewerSlice';

interface VideoProps {
  url: string;
}

const Video = ({ url }: VideoProps) => {
  // const {
  //   setOpenImageViewer,
  //   setImageViewerSrc
  // } = useContext(conversationsContext);
  const dispatch = useAppDispatch();

  const containerImgRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [videoProgress, setVideoProgress] = useState(0);
  const [play, setPlay] = useState(false);
  const [width, setWidth] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', handleVideoLoaded);
      videoRef.current?.addEventListener('timeupdate', handleTimeUpdate);
    }
    document.addEventListener('keydown', handleSpaceKey);
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadedmetadata', handleVideoLoaded);
        videoRef.current?.removeEventListener('timeupdate', handleTimeUpdate);
      }
      document.removeEventListener('keydown', handleSpaceKey);
    };
  }, []);

  useEffect(() => {
    setWidth(videoRef.current.clientWidth);
  }, [videoRef.current?.clientWidth]);

  const handleVideoProgress = () => {
    if (videoRef.current) {
      const progress =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setVideoProgress(progress);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    if (videoRef.current) {
      const progress = typeof newValue === 'number' ? newValue : 0;
      setVideoProgress(progress);
      const newTime = (progress / 100) * videoRef.current.duration;
      videoRef.current.currentTime = newTime;
      console.log(videoRef.current.currentTime);
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleVideoLoaded = () => {
    if (videoRef.current) {
      videoRef.current.addEventListener('play', handlePlay);
      videoRef.current.addEventListener('pause', handlePause);
      videoRef.current.addEventListener('timeupdate', handleVideoProgress);
    }
  };

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setPlay(true);
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setPlay(false);
    }
  };

  const handleRewind = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10;
    }
  };

  const handlePictureInPicture = async () => {
    try {
      if (videoRef.current && document.pictureInPictureEnabled) {
        if (!document.pictureInPictureElement) {
          dispatch(setOpenMediaViewer(false));
          dispatch(setMediaViewerItems([]));
          await videoRef.current.requestPictureInPicture();
        } else {
          await document.exitPictureInPicture();
        }
      }
    } catch (error) {
      console.error('Error al cambiar a Picture-in-Picture:', error);
    }
  };

  const handleFullscreen = () => {
    try {
      if (videoRef.current && document.fullscreenEnabled) {
        if (!document.fullscreenElement) {
          videoRef.current.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      }
    } catch (error) {
      console.error('Error al cambiar a Fullscreen:', error);
    }
  };

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const handleSpaceKey = (event: KeyboardEvent) => {
    if (event.code === 'Space') {
      event.preventDefault();
      handleTogglePlayPause();
    }
  };

  const handleTogglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        handlePlay();
      } else {
        handlePause();
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '100%',
        maxWidth: '100%',
        position: 'relative',
      }}
      ref={containerImgRef}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '2rem',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            maxWidth: `${width}px`,
            margin: '0 auto',
          }}
        >
          <IconButton onClick={handleFullscreen}>
            <Fullscreen
              sx={{
                color: 'white',
              }}
            />
          </IconButton>
          <IconButton onClick={handlePictureInPicture}>
            <PictureInPictureAlt
              sx={{
                color: 'white',
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <video
        src={url}
        autoPlay
        controls={false}
        ref={videoRef}
        style={{
          width: 'max-content',
          maxHeight: '100%',
          objectFit: 'contain',
          borderRadius: '2px',
        }}
        onClick={handleTogglePlayPause}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '2rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxWidth: `${width}px`,
            margin: '0 auto',
          }}
        >
          {play ? (
            <IconButton onClick={handlePause}>
              <Pause
                sx={{
                  color: 'white',
                }}
              />
            </IconButton>
          ) : (
            <IconButton onClick={handlePlay}>
              <PlayArrow
                sx={{
                  color: 'white',
                }}
              />
            </IconButton>
          )}
          <IconButton onClick={handleRewind}>
            <Replay10
              sx={{
                color: 'white',
              }}
            />
          </IconButton>
          <Slider
            value={videoProgress}
            onChange={handleSliderChange}
            aria-label="Video progress slider"
            min={0}
            max={100}
            sx={{
              color: 'white',
              '& .MuiSlider-thumb': {
                width: '0.7rem',
                height: '0.7rem',
              },
              '& .MuiSlider-track': {
                height: '0.25rem',
              },
            }}
            valueLabelFormat={formatTime(currentTime)}
            valueLabelDisplay="auto"
          />
          <Volume videoRef={videoRef} />
        </Box>
      </Box>
    </Box>
  );
};

export default Video;
