// Components/ui
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { IconButton } from '@mui/material';
// Redux
import { useAppDispatch } from '../../../../../../../../../../hooks/useAppDispatch';
import {
  deleteFloatingWindow,
  removeChatById,
  setOpenChatStatus,
} from '../../../../../../../../../../redux/features/chatsSlice/chatsSlice';
import { ChatType } from '@trii/types/dist/Conversations';
// Types
const styles = {
  iconsContainer: {
    display: 'flex',
    gap: 1.5,
  },
  iconButton: {
    padding: '2px',
    height: '20px',
    width: '20px',
  },
};

interface Props {
  chatId: string;
  chatType: ChatType;
}

const Buttons = ({ chatId, chatType }: Props) => {
  const dispatch = useAppDispatch();

  function handleClose() {
    dispatch(setOpenChatStatus({ id: chatId, status: false }));
    dispatch(removeChatById(chatId));

    const data = {
      conversationId: chatType === ChatType.EXTERNAL ? chatId : '',
      chatId: chatType === ChatType.EXTERNAL ? '' : chatId,
    };

    dispatch(deleteFloatingWindow(data));
  }

  return (
    <div style={styles.iconsContainer}>
      <IconButton
        onClick={() => dispatch(setOpenChatStatus({ id: chatId, status: false }))}
        disableRipple
        style={{ ...styles.iconButton, paddingBottom: 8 }}
      >
        <MinimizeIcon sx={{ fontSize: 15 }} />
      </IconButton>
      <IconButton onClick={handleClose} disableRipple style={styles.iconButton}>
        <CloseIcon sx={{ fontSize: 15 }} />
      </IconButton>
    </div>
  );
};

export default Buttons;
