import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { TextField, IconButton, Slide, Box, Tooltip, styled } from '@mui/material';
import { AudioBox, MediaFileImage } from './components';
import { AddCircleOutline, AttachFile, Mic, Photo, Send } from '@mui/icons-material';
// Types
import { IMessage } from '@trii/types/dist/Common/Messages';
import { PayloadAction } from '@reduxjs/toolkit';
import { ChatType } from '@trii/types/dist/Conversations';
import { IChannelInfo } from '@trii/types/dist/Common/Channels';
import {
  DocumentType,
  Media,
  MediaFile,
  UploadMedia,
} from '../../../../../../../../redux/features/messagesSlice/types';
// Redux
import {
  sendMessageThunk,
  uploadMedia,
} from '../../../../../../../../redux/features/messagesSlice/messagesSlice';
import { useSelector } from 'react-redux';
import { selectSpaceInfo } from '../../../../../../../../redux/features/spaceSlice/spaceSlice';
import { selectUser } from '../../../../../../../../redux/features/userSlice/userSlice';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import {
  deleteChatFile,
  deleteChatFiles,
  endRecordAudioMode,
  handleFileUpload,
  startAudioRecordMode,
  updateChatInput,
  deleteChatDocument,
  deleteChatDocuments,
  handleDocumentUpload,
} from '../../../../../../../../redux/features/chatsSlice/chatsSlice';
// Utils
import ObjectID from 'bson-objectid';
import {
  createBaseMessage,
  getFileType,
  verifyAllLoaded,
} from '../../../../../../../../redux/features/messagesSlice/functions';
// Context
import { DateTimeContext } from '../../../../../../../../context/DateTime/DateTimeContext';
import { AudioMessagesContext } from '../../../../../../../../context/AudioMessages/AudioMessagesContext';
// Db
import { conversationsDbWorker } from '../../../../../../../../db/conversationsDb';
import { chatsDbWorker } from '../../../../../../../../db/chatDb';

// Styled Components
const FooterContainer = styled('div')(({ theme }) => ({
  width: '100%',
  borderTop: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  minHeight: '45px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const MultimediaContainer = styled('div')({
  padding: 8,
  display: 'flex',
  overflowX: 'auto',
});

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});

const IconButtonsContainer = styled('div')({
  display: 'flex',
});

type FooterProps = {
  chatType: ChatType;
  inputValue: string;
  conversationId: string;
  channelInfo: IChannelInfo;
  contactInfoId: string;
  remoteAddress: string;
  chatId: string;
  recordAudioMode: boolean;
  chatMultimedia: MediaFile[];
  chatDocuments: DocumentType[];
  messageLoading: string[];
  setMessageLoading: Dispatch<SetStateAction<string[]>>;
};
const Footer = ({
  chatType,
  inputValue,
  conversationId,
  channelInfo,
  contactInfoId,
  chatId,
  remoteAddress,
  recordAudioMode,
  chatMultimedia,
  chatDocuments,
  messageLoading,
  setMessageLoading,
}: FooterProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [uploadingMedia, setUploadingMedia] = useState(false);

  const { datetime } = useContext(DateTimeContext);
  const { recordedAudioBase64 } = useContext(AudioMessagesContext);

  const spaceInfo = useSelector(selectSpaceInfo);
  const userInfo = useSelector(selectUser);

  const containerRef = useRef<HTMLDivElement>(null);

  function sendMessageToDb(message: IMessage) {
    if (chatType === ChatType.EXTERNAL) {
      conversationsDbWorker.postMessage({
        action: 'setMessage',
        data: message,
      });
    } else {
      chatsDbWorker.postMessage({
        action: 'setMessage',
        data: message,
      });
    }
  }

  const handleSendMessage = () => {
    if (chatMultimedia?.length > 0) {
      sendMediaMessage();
    } else if (chatDocuments?.length > 0) {
      sendDocuments();
    } else {
      sendTextMessage();
    }
  };

  const sendTextMessage = async () => {
    if (!inputValue) return;

    let messageReference;

    if (chatType === ChatType.EXTERNAL) {
      messageReference = {
        messageId: '',
        externalId: '',
        channelId: '',
      };

      // if (messageReply) {
      //   messageReference.messageId = messageReply.id;
      //   messageReference.externalId = messageReply.externalId;
      //   messageReference.channelId = messageReply.channelInfo?.id;
      // }
    } else {
      messageReference = {
        messageId: '',
        channelId: '',
      };

      // if (messageReply) {
      //   messageReference.messageId = messageReply.id;
      //   messageReference.channelId = conversationSelected.channelInfo?.id;
      // }
    }

    // if (messageReply) {
    //   messageReference.messageId = messageReply.id;
    //   messageReference.channelId = conversationSelected.channelInfo?.id;
    // }

    const newMessage = createBaseMessage({
      spaceInfo,
      conversationId,
      userInfo,
      channelInfo,
      contactInfoId,
      remoteAddress,
      chatType,
      timestamp: datetime.getDateTime(),
    });

    newMessage.text = {
      body: inputValue,
      previewUrl: false,
    };

    sendMessageToDb(newMessage);

    dispatch(sendMessageThunk({ message: newMessage, chatType: chatType }));
    // setMessageReply(null);
    // setIsReplying(false);
    dispatch(updateChatInput({ id: chatId, value: '' }));
  };

  const sendMediaMessage = async () => {
    const images = chatMultimedia.filter((file) => {
      if (file) {
        const type = getFileType(file.mimeType);
        if (type === 'image') {
          return file;
        }
      }
    });
    const videos = chatMultimedia.filter((file) => {
      if (file) {
        const type = getFileType(file.mimeType);
        if (type === 'video') {
          return {
            ...file,
            animated: false,
          };
        }
      }
    });
    const newMessage = createBaseMessage({
      spaceInfo,
      conversationId,
      userInfo,
      channelInfo,
      contactInfoId,
      remoteAddress,
      chatType,
      timestamp: datetime.getDateTime(),
      images,
      videos,
    });

    sendMessageToDb(newMessage);
    dispatch(deleteChatFiles(chatId));

    setUploadingMedia(true);

    const newImages = ((images.length > 0 &&
      (await handleUpload(images, newMessage.id))) ||
      []) as MediaFile[];
    const newVideos = ((videos.length > 0 &&
      (await handleUpload(videos, newMessage.id))) ||
      []) as MediaFile[];

    const isAllImagesCharged = images && verifyAllLoaded(images);
    const isAllVideosCharged = videos && verifyAllLoaded(videos);

    newMessage.images = newImages;
    newMessage.videos = newVideos;
    newMessage.isLoaded = isAllImagesCharged && isAllVideosCharged;

    if (newImages && newVideos && isAllImagesCharged && isAllVideosCharged) {
      const response = await dispatch(
        sendMessageThunk({ message: newMessage, chatType: chatType })
      );

      console.log('Response', response);
      //@ts-ignore
      sendMessageToDb(response.payload.message);
    }

    setUploadingMedia(false);
  };

  const sendAudio = async () => {
    const newMessage = createBaseMessage({
      spaceInfo,
      conversationId,
      userInfo,
      channelInfo,
      contactInfoId,
      remoteAddress,
      chatType,
      timestamp: datetime.getDateTime(),
    });

    newMessage.audio = {
      id: ObjectID().toString(),
      url: recordedAudioBase64,
      mimeType: 'audio/webm',
      filename: 'audio.webm',
    };

    dispatch(endRecordAudioMode(chatId));
    sendMessageToDb(newMessage);
    setMessageLoading([...messageLoading, newMessage.id]);
    await dispatch(sendMessageThunk({ message: newMessage, chatType: chatType }));
    setMessageLoading(messageLoading.filter((msg) => msg !== newMessage.id));
  };

  const sendDocuments = async () => {
    const newMessage = createBaseMessage({
      spaceInfo,
      conversationId,
      userInfo,
      channelInfo,
      contactInfoId,
      remoteAddress,
      chatType,
      timestamp: datetime.getDateTime(),
    });

    newMessage.documents = chatDocuments;

    sendMessageToDb(newMessage);
    dispatch(deleteChatDocuments(chatId));

    const newDocuments = (await handleUpload(
      chatDocuments,
      newMessage.id
    )) as DocumentType[];

    const verifyAllLoaded = newDocuments.every((document) => {
      if (document) {
        return document.url !== '';
      }
    });

    if (newDocuments && verifyAllLoaded) {
      newMessage.documents = newDocuments;
      newMessage.isLoaded = verifyAllLoaded;

      const response = await dispatch(
        sendMessageThunk({ message: newMessage, chatType: chatType })
      );

      //@ts-ignore
      sendMessageToDb(response.payload.message);
    }
  };

  const handleMultimediaIconClick = () => handleFileInputClick('image/*, video/*');

  const handleDocumentIconClick = () =>
    handleFileInputClick('.doc,.docx,.pdf,.txt,.xls,.xlsx,.ppt,.pptx');

  const handleFileInputClick = (accept: string) => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = accept;
    inputElement.multiple = true;
    inputElement.addEventListener('change', (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        // Check if the files are multimedia like images and videos or documents
        const isDocument = accept.includes('.doc');
        if (isDocument) {
          dispatch(handleDocumentUpload(target, chatId));
        } else {
          dispatch(handleFileUpload(target, chatId));
        }

        dispatch(updateChatInput({ id: chatId, value: '' }));
      }
    });
    inputElement.click();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateChatInput({ id: chatId, value: e.target.value }));
  };
  const handleUpload = async (
    files: MediaFile[] | DocumentType[],
    messageId: string
  ): Promise<MediaFile[] | DocumentType[]> => {
    let newFiles = [...files] as MediaFile[] | DocumentType[];

    files.map((file: MediaFile | DocumentType) => {
      if (file && file.id && !messageLoading.includes(messageId)) {
        setMessageLoading([...messageLoading, messageId]);
      }
    });

    for (const data of files) {
      if (data && data.file) {
        const { file, filename, id, mimeType } = data;
        const formData = new FormData();

        formData.append('file', file, filename);

        let uploadMediaData: UploadMedia = {
          file: formData,
          name: filename,
          id,
          URLParams: {
            module: chatType === ChatType.EXTERNAL ? 'messages' : 'chat',
            folderType: mimeType.includes('image') ? 'images' : 'videos',
            chatId:
              chatType === ChatType.DIRECT || chatType === ChatType.GROUP
                ? chatId
                : null,
          },
        };

        const result = (await dispatch(
          uploadMedia(uploadMediaData)
        )) as PayloadAction<Media>;

        if (result && result.payload) {
          const { url, id } = result.payload;

          newFiles = newFiles.map((file: MediaFile | DocumentType) => {
            if (file.id === id) {
              return { ...file, url };
            }
            return file;
          });
        }
      }
    }

    setMessageLoading(messageLoading.filter((msg) => msg !== messageId));

    return newFiles;
  };

  return (
    <FooterContainer>
      {recordAudioMode ? (
        <Slide
          direction="right"
          in={recordAudioMode}
          container={containerRef.current}
        >
          <Box width={'100%'}>
            <AudioBox sendAudio={sendAudio} />
          </Box>
        </Slide>
      ) : chatMultimedia?.length > 0 ? (
        <MultimediaContainer>
          {chatMultimedia.map((file) => (
            <MediaFileImage
              key={file.id}
              media={file}
              chatId={chatId}
              onDelete={() => dispatch(deleteChatFile({ chatId, fileId: file.id }))}
            />
          ))}
        </MultimediaContainer>
      ) : chatDocuments?.length > 0 ? (
        <MultimediaContainer>
          {chatDocuments.map((file) => (
            <MediaFileImage
              key={file.id}
              document={file}
              chatId={chatId}
              onDelete={() =>
                dispatch(deleteChatDocument({ chatId, fileId: file.id }))
              }
            />
          ))}
        </MultimediaContainer>
      ) : (
        <CustomTextField
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault(); // Prevents adding a new line in the input
              handleSendMessage();
            }
          }}
          fullWidth
          value={inputValue}
          size="small"
          variant="outlined"
          placeholder="Type a message"
          autoComplete="off"
        />
      )}
      <IconButtonsContainer>
        {!inputValue &&
          chatMultimedia?.length === 0 &&
          chatDocuments?.length === 0 && (
            <Tooltip title={t('chat.sendAudioClip')}>
              <IconButton onClick={() => dispatch(startAudioRecordMode(chatId))}>
                <Mic />
              </IconButton>
            </Tooltip>
          )}
        {!inputValue &&
          chatMultimedia?.length === 0 &&
          (chatDocuments?.length === 0 ? (
            <Tooltip title={t('chat.documents')}>
              <IconButton onClick={handleDocumentIconClick}>
                <AttachFile />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t('chat.documents')}>
              <IconButton onClick={handleDocumentIconClick}>
                <AddCircleOutline />
              </IconButton>
            </Tooltip>
          ))}
        {!inputValue &&
          chatDocuments?.length === 0 &&
          (chatMultimedia?.length === 0 ? (
            <Tooltip title={'Multimedia'}>
              <IconButton onClick={handleMultimediaIconClick}>
                <Photo />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={'Multimedia'}>
              <IconButton
                disabled={uploadingMedia}
                onClick={handleMultimediaIconClick}
              >
                <AddCircleOutline />
              </IconButton>
            </Tooltip>
          ))}
        {(inputValue || chatMultimedia?.length > 0 || chatDocuments?.length > 0) && (
          <IconButton
            onClick={handleSendMessage}
            disableRipple
            disabled={uploadingMedia}
          >
            <Send sx={{ fontSize: 15 }} />
          </IconButton>
        )}
      </IconButtonsContainer>
    </FooterContainer>
  );
};

export default Footer;
