import { useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../../../../../../../../../hooks/useAppDispatch';
// import { selectUploadMediaStatus } from 'redux/features/messagesSlice/messagesSlice';
// Context
// import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
// import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components
import { Loader } from './components';
// Icons
import { PlayCircle } from '@mui/icons-material';
// Types
import { MediaFile } from '../../../../../../../../../../../../../../redux/features/messagesSlice/types';
import { MessageHeaderType } from '@trii/types/dist/Common/Messages';
import {
  getFileType,
  Markdown,
} from '../../../../../../../../../../../../../../redux/features/messagesSlice/functions';
import {
  setCurrentIndex,
  setMediaViewerItems,
  setOpenMediaViewer,
} from '../../../../../../../../../../../../../../redux/features/mediaViewerSlice/mediaViewerSlice';
import useSAS from '../../../../../../../../../../../../../../hooks/useSAS';

type PhotoProps = {
  files: MediaFile[];
  // handleTryAgain?: () => void;
  messageId: string;
  type: 'sticker' | 'file';
  templateFileType?: MessageHeaderType;
  messageLoading: string[];
};

const ImageContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}));

const ImageBox = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

const OverlayBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '1px',
  left: '1px',
  width: 'calc(100% - 2px)',
  height: 'calc(100% - 2px)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.8)',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
}));

const CaptionTypography = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  lineHeight: 1.5,
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  textAlign: 'justify',
  display: 'block',
  padding: '8px',
  color: theme.palette.text.primary,
  whiteSpace: 'pre-line',
}));

const Image = ({
  files,
  messageId,
  type,
  templateFileType,
  messageLoading,
}: PhotoProps) => {
  const dispatch = useAppDispatch();
  // const { setOpenImageViewer, setImageViewerSrc, setCurrentIndex } =
  //   useContext(conversationsContext);
  // const { getFileType, messageLoading } = useContext(messagesContext);

  // const uploadImgStatus = useSelector(selectUploadMediaStatus);

  const [filesToRender, setFilesToRender] = useState<MediaFile[]>(files);
  const [imgSize, setImgSize] = useState<number>(15);

  const isLoading = messageLoading.includes(messageId);

  // console.log('messageId', messageId);
  // console.log('files', files);
  // console.log('messageLoading', messageLoading);

  const handleOpenModal = (index: number) => {
    dispatch(setOpenMediaViewer(true));
    dispatch(setMediaViewerItems(files));
    dispatch(setCurrentIndex(index));
  };

  useEffect(() => {
    switch (type) {
      case 'sticker':
        setImgSize(6);
        break;
      case 'file':
        if (files.length > 2 && files.length < 4) {
          setFilesToRender(files.slice(0, 2));
        } else if (files.length > 4) {
          setFilesToRender(files.slice(0, 4));
        }
        if (files.length >= 4) {
          setImgSize(8);
        } else {
          setImgSize(13);
        }
        break;
      default:
        break;
    }
  }, [files]);

  return (
    <ImageContainer>
      <GridContainer
        sx={{
          gridTemplateColumns: `${filesToRender.length > 1 ? '1fr 1fr' : '1fr'}`,
        }}
      >
        {isLoading && <Loader isLoading={isLoading} />}
        {filesToRender?.map((image, i) => {
          const type = getFileType(image?.mimeType) || templateFileType;
          const isSticker = image?.mimeType === 'image/sticker';
          const imageSize = isSticker ? '130px' : `${imgSize}rem`;

          return (
            <ImageBox
              key={i}
              sx={{
                height: imageSize,
                width: imageSize,
              }}
            >
              <OverlayBox
                sx={{
                  display: `${
                    (((files.length > 2 && files.length < 4) || files.length > 4) &&
                      i === filesToRender.length - 1) ||
                    type === 'video' ||
                    type === MessageHeaderType.VIDEO
                      ? 'flex'
                      : 'none'
                  }`,
                }}
                onClick={() => handleOpenModal(i)}
              >
                {((files.length > 2 && files.length < 4) || files.length > 4) &&
                i === filesToRender.length - 1 ? (
                  <StyledTypography variant="h4">
                    +{files.length - filesToRender.length}
                  </StyledTypography>
                ) : (
                  (type === 'video' || type === MessageHeaderType.VIDEO) && (
                    <StyledIconButton>
                      <PlayCircle
                        sx={{
                          width: '4rem',
                          height: '4rem',
                        }}
                      />
                    </StyledIconButton>
                  )
                )}
              </OverlayBox>
              <CardMedia
                component={`${
                  type === 'image' || type === MessageHeaderType.IMAGE
                    ? 'img'
                    : 'video'
                }`}
                sx={{
                  cursor: 'pointer',
                  padding: '1px',
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: '2px',
                }}
                onClick={() => handleOpenModal(i)}
                image={image?.url}
                alt={image?.caption}
                width="100%"
                height="100%"
              />
            </ImageBox>
          );
        })}
      </GridContainer>
      {files.length <= 1 && (
        <CaptionTypography
          variant="caption"
          dangerouslySetInnerHTML={{
            __html: Markdown(files[0]?.caption || '', false),
          }}
        />
      )}
    </ImageContainer>
  );
};

export default Image;
