// Types
import { RootState } from '../../rootReducer';
// Utils
import { createSelector } from '@reduxjs/toolkit';

export const selectAudioRecord = (state: RootState) => state.AudioRecord;

export const selectRecordAudioStream = createSelector(
  selectAudioRecord,
  (audioRecord) => audioRecord.recordAudioStream
);

export const selectUploadAudiosRejected = createSelector(
  selectAudioRecord,
  (audioRecord) => audioRecord.uploadAudiosRejected
);
