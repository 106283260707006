import { combineReducers } from '@reduxjs/toolkit';
// Reducers
import poolReducer from './features/poolSlice/poolSlice';
import sessionReducer from './features/sessionSlice/sessionSlice';
import spaceSlice from './features/spaceSlice/spaceSlice';
import spacesSlice from './features/spacesSlice/spacesSlice';
import themeReducer from './features/themeSlice/themeSlice';
import userReducer from './features/userSlice/userSlice';
import usersReducer from './features/usersSlice/usersSlice';
import contactsReducer from './features/contactsSlice/contactsSlice';
import callReducer from './features/callSlice/callSlice';
import chatsReducer from './features/chatsSlice/chatsSlice';
import messagesReducer from './features/messagesSlice/messagesSlice';
import audioRecordReducer from './features/audioRecordSlice/audioRecordSlice';
import mediaViewerReducer from './features/mediaViewerSlice/mediaViewerSlice';

const rootReducer = combineReducers({
  Spaces: spacesSlice,
  Space: spaceSlice,
  Session: sessionReducer,
  Pool: poolReducer,
  Theme: themeReducer,
  User: userReducer,
  Users: usersReducer,
  Contacts: contactsReducer,
  Calls: callReducer,
  Chats: chatsReducer,
  Messages: messagesReducer,
  AudioRecord: audioRecordReducer,
  MediaViewer: mediaViewerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
