// Types
import DocumentExtension from './types/DocumentExtension';
// Utils
import textDocuments from './utils/documentFiles';
// Components/ui
import { Typography } from '@mui/material';

interface Props {
  documentType: DocumentExtension;
}

const DocumentIcon = ({ documentType }: Props) => {
  const textImageData = textDocuments.find(
    (textDocument) => textDocument.name === documentType
  );

  if (textImageData) {
    return (
      <img
        src={textImageData.src}
        alt={'document type'}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    );
  }

  return (
    <Typography
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.6rem',
        fontWeight: 600,
        color: 'primary.contrastText',
        backgroundColor: 'primary.light',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          textAlign: 'center',
          width: '100%',
          padding: '0 2px',
        }}
      >
        {documentType?.toUpperCase()}
      </div>
    </Typography>
  );
};

export default DocumentIcon;
