import Dexie from 'dexie';
// Types
import { IMessage } from '@trii/types/dist/Common/Messages';

export const chatsDbWorker = new Worker('/chat-db.worker.js');

class ChatDatabase extends Dexie {
  messages: Dexie.Table<IMessage, string>;

  constructor() {
    super('ChatDatabase');

    this.version(1.4).stores({
      messages: 'id, shardKey',
    });

    this.messages = this.table('messages');
  }

  async getAllMessages(conversationId: string) {
    return await this.messages
      .where('conversationId')
      .equals(conversationId)
      .toArray();
  }
}

const chatsDb = new ChatDatabase();

export default chatsDb;
