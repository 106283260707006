import { useEffect, useState } from 'react';
// Components/ui
import { Header, Body, Footer } from './components';
import { Box, Grow, LinearProgress } from '@mui/material';
// Types
import { ChatType } from '@trii/types/dist/Conversations';
import { ClientConversation } from '../../../../../../redux/features/chatsSlice/types';
// Redux
import { useSelector } from 'react-redux';
import { selectedTheme } from '../../../../../../redux/features/themeSlice/themeSlice';
import { selectMessagesStatusById } from '../../../../../../redux/features/messagesSlice/selectors';
import { getMessagesRelationId } from '../../../../../../redux/features/messagesSlice/functions';
import { AudioMessagesProvider } from '../../../../../../context/AudioMessages/AudioMessagesProvider';
import { selectRecordAudioStream } from '../../../../../../redux/features/audioRecordSlice/selectors';
import { endRecordAudioMode } from '../../../../../../redux/features/chatsSlice/chatsSlice';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';

type Props = {
  data: ClientConversation;
};

const Chat = ({ data }: Props) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [messageLoading, setMessageLoading] = useState<string[]>([]);

  const id = getMessagesRelationId(data);

  const onSelectedTheme = useSelector(selectedTheme);
  const messageStatus = useSelector(selectMessagesStatusById(id));
  const recordAudioStream = useSelector(selectRecordAudioStream);

  useEffect(() => {
    setOpen(true);
  }, []);


  
  // console.log("UserInfo", data);

  return (
    <Grow
      in={open}
      unmountOnExit
      style={{ transformOrigin: '0 0 0' }}
      {...(open ? { timeout: 1000 } : {})}
    >
      <Box
        sx={{
          width: '270px',
          //@ts-ignore
          border: `1px solid ${onSelectedTheme.palette.divider}`,
          //@ts-ignore
          backgroundColor: onSelectedTheme.palette.background.default,
          borderRadius: '4px',
          boxShadow: '0 -1px 6px 0 rgba(0,0,0,.25)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header
          chatId={data.id}
          userName={
            data.type === ChatType.EXTERNAL ? data.contactInfo.name : data.chatName
          }
          chatType={data.type}
          channelType={data?.channelInfo?.type}
          chatImage={data.chatImage}
        />
        {messageStatus === 'loading' && <LinearProgress />}
        <Body
          chatType={data.type}
          id={id}
          conversationId={data.id}
          messageLoading={messageLoading}
          filesDoneLoading={data.filesDoneLoading}
          contactInfo={data}
        />
        <AudioMessagesProvider
          recordAudioStream={recordAudioStream}
          endRecordAudioMode={() => dispatch(endRecordAudioMode(data.id))}
        >
          <Footer
            chatDocuments={data.documents}
            inputValue={data.inputValue}
            conversationId={data.id}
            channelInfo={data.channelInfo}
            contactInfoId={data.contactInfo?.id}
            remoteAddress={data.remoteAddress}
            chatId={data.id}
            chatType={data.type}
            recordAudioMode={data.recordAudioMode}
            chatMultimedia={data.files}
            messageLoading={messageLoading}
            setMessageLoading={setMessageLoading}
          />
        </AudioMessagesProvider>
      </Box>
    </Grow>
  );
};

export default Chat;
