import axios from 'axios';
// Utils
import getRequestConfig from '../../functions/getRequestConfig';
// Types
import { GetMessagesData, UploadURLParams } from './types';
import { IMessage } from '@trii/types/dist/Common/Messages';

const fetchMessages = async (
  jwt: string,
  URL: string,
  getMessagesData: GetMessagesData
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const { conversationId, pos } = getMessagesData;

  const response = await axios.get<IMessage[]>(
    `${URL}/Messages?conversationId=${conversationId}&limit=20&pos=${pos}`,
    requestConfig
  );

  return response.data;
};

const sendMessages = async (jwt: string, URL: string, message: IMessage) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const messageJson = JSON.stringify(message);

  const response = await axios.post(`${URL}/Messages`, messageJson, requestConfig);

  return response.data;
};

const uploadAudio = async (
  jwt: string,
  URL: string,
  file: FormData,
  name: string,
  id: string,
  URLParams: UploadURLParams
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const response = await axios.post(
    `${URL}/upload/audio?module=${URLParams.module}&folderType=${URLParams.folderType}`,
    file,
    requestConfig
  );
  const data = {
    name,
    url: response.data,
    id,
  };
  return data;
};

const uploadMedia = async (
  jwt: string,
  URL: string,
  file: FormData,
  name: string,
  id: string,
  URLParams?: UploadURLParams
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const UPLOAD_URL =
    URLParams && URLParams.module === 'messages'
      ? `${URL}/upload?module=${URLParams.module}&folderType=${URLParams.folderType}`
      : URLParams && URLParams.module === 'chat'
      ? `${URL}/upload?module=${URLParams.module}&folderType=${URLParams.folderType}&chatId=${id}`
      : `${URL}/upload`;

  const response = await axios.post(UPLOAD_URL, file, requestConfig);

  const data = {
    name,
    url: response.data,
    id,
  };
  return data;
};

export default { fetchMessages, sendMessages, uploadAudio, uploadMedia };
