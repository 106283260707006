// Components/ui
import { Box, useTheme } from '@mui/material';
import { Date, Message } from './components';
// Redux
import { useSelector } from 'react-redux';
// import { selectMessagesById } from '../../../../../../../../redux/features/messagesSlice/selectors';
import { selectSpaceInfo } from '../../../../../../../../redux/features/spaceSlice/spaceSlice';
// Utils
import moment from 'moment';
// Db
import conversationsDb from '../../../../../../../../db/conversationsDb';
import chatsDb from '../../../../../../../../db/chatDb';
// Types
import { MessageType } from '@trii/types/dist/Common/Messages';
import { ChatType } from '@trii/types/dist/Conversations';
// Hooks
import { useLiveQuery } from 'dexie-react-hooks';
import { useAutoScroll } from '../../../../../../../../hooks/useAutoScroll';
import { ClientConversation } from '../../../../../../../../redux/features/chatsSlice/types';

const FORMAT_DATE = 'DD/MM/YYYY';

interface BodyProps {
  id: string;
  conversationId: string;
  contactInfo: ClientConversation;
  chatType: ChatType;
  filesDoneLoading: boolean;
  messageLoading: string[];
}

const Body = ({
  id,
  chatType,
  conversationId,
  filesDoneLoading,
  messageLoading,
  contactInfo
}: BodyProps) => {
  const theme = useTheme();

  let previousDate: string | null = null;

  const today = moment();
  const todayFormat = today.format(FORMAT_DATE);
  const yesterday = today.subtract(1, 'days').format(FORMAT_DATE);

  const space = useSelector(selectSpaceInfo);

  const externalMessagesDb = useLiveQuery(async () => {
    const messages = await conversationsDb.messages
      .where('contactId')
      .equals(id)
      .filter((message) => message.type !== MessageType.INFO)
      .toArray();

    return messages.sort(
      (a, b) =>
        moment(a.timestamp).toDate().getTime() -
        moment(b.timestamp).toDate().getTime()
    );
  }, [id, conversationsDb.messages]);

  const internalMessagesDb = useLiveQuery(
    async () =>
      await chatsDb.messages
        .where('shardKey')
        .equals(`${space.id}_chat_${conversationId}`)
        .filter((message) => message.type !== MessageType.INFO)
        .toArray(),
    [chatsDb.messages]
  );

  const messages =
    chatType === ChatType.EXTERNAL ? externalMessagesDb : internalMessagesDb;

  // Pass the message length or messages themselves as the dependency to trigger the hook when new messages are added
  const { containerRef, handleScroll } = useAutoScroll<HTMLDivElement>(
    messages,
    filesDoneLoading,
    conversationId
  );

  return (
    <Box
      ref={containerRef}
      onScroll={handleScroll}
      sx={{
        borderTop: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        height: '320px',
        flexDirection: 'column',
        padding: '0.5rem',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {messages?.map((message, i) => {
        // Format both dates to 'YYYY-MM-DD' to compare only the date part
        const currentDate = moment(message.timestamp).format('YYYY-MM-DD');
        const shouldRenderDate = previousDate !== currentDate;
        // Update the previousDate for the next iteration
        previousDate = currentDate;

        return (
          <div key={i}>
            {shouldRenderDate && (
              <Date
                day={moment(message.timestamp).format(FORMAT_DATE)}
                today={todayFormat}
                yesterday={yesterday}
              />
            )}
            <Message
              data={message}
              chatType={chatType}
              conversationId={conversationId}
              messageLoading={messageLoading}
              contactInfo={contactInfo}
            />
          </div>
        );
      })}
      <div />
    </Box>
  );
};

export default Body;
