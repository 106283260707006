import { useState, useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Selectors
// import { selectUploadMediaStatus } from "redux/features/messagesSlice/messagesSlice";
// Components/ui
import { Box, CircularProgress, Fab, IconButton } from '@mui/material';
// Icons
import { Clear, FileDownload, FileUpload } from '@mui/icons-material';
// Context
// import { messagesContext } from "features/Views/Conversations/context/MessagesProvider/MessagesProvider";

interface LoaderProps {
  isLoading: boolean;
  handleDownload: (url: string) => void;
  url: string;
}

const Loader = ({ isLoading, handleDownload, url }: LoaderProps) => {
  // const { messageLoading } = useContext(messagesContext);
  const [cancel, setCancel] = useState<boolean>(false);
  // const uploadStatus = useSelector(selectUploadMediaStatus);

  const handleMouseOver = () => {
    setCancel(true);
  };

  const handleMouseOut = () => {
    setCancel(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Fab
        aria-label="action"
        sx={{
          width: 35,
          height: 35,
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          '&:hover': {
            bgcolor: 'rgba(0, 0, 0, 0.6)',
          },
        }}
        onMouseOver={() => handleMouseOver()}
        onMouseOut={() => handleMouseOut()}
      >
        {url === '' ? (
          isLoading && cancel ? (
            <IconButton>
              <Clear sx={{ color: 'white' }} />
            </IconButton>
          ) : (
            <IconButton
            // onClick={() => handleTryAgain()}
            >
              <FileUpload sx={{ color: 'white' }} />
            </IconButton>
          )
        ) : (
          <IconButton onClick={() => handleDownload(url)}>
            <FileDownload sx={{ color: 'white' }} />
          </IconButton>
        )}
      </Fab>
      {isLoading && (
        <CircularProgress
          size={45}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zid: 1,
          }}
        />
      )}
    </Box>
  );
};

export default Loader;
