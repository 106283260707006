import React from 'react';
// Components/ui
import { Card, CardMedia, IconButton, styled } from '@mui/material';
import { DocumentIcon } from './components';
import CloseIcon from '@mui/icons-material/Close';
// Types
import {
  DocumentExtension,
  DocumentType,
  MediaFile,
} from '../../../../../../../../../../redux/features/messagesSlice/types';

// Styled components
const StyledCard = styled(Card)({
  width: 45,
  height: 45,
  position: 'relative',
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
  height: 18,
  width: 18,
}));

interface MediaFileImageProps {
  media?: MediaFile;
  document?: DocumentType;
  chatId: string;
  onDelete?: (fileId: string, chatId: string) => void;
}

const MediaFileImage: React.FC<MediaFileImageProps> = ({
  media,
  document,
  chatId,
  onDelete,
}) => {
  const handleDelete = () => {
    if (onDelete) {
      onDelete(media?.id || document?.id, chatId);
    }
  };

  return (
    <StyledCard>
      {media && (
        <CardMedia
          component="img"
          image={media.previewUrl || media.url}
          alt={media.caption || 'Uploaded file'}
          sx={{ width: '100%', height: '100%' }}
        />
      )}
      {document && (
        <DocumentIcon
          documentType={document?.filename?.split('.').pop() as DocumentExtension}
        />
      )}
      <StyledIconButton onClick={handleDelete}>
        <CloseIcon sx={{ fontSize: 15 }} />
      </StyledIconButton>
    </StyledCard>
  );
};

export default MediaFileImage;
