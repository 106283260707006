import { useEffect, useRef, useState } from 'react';

const useMediaRecorder = (
  recordAudioStream: MediaStream
): MediaRecorderHookResult => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordedAudioURL, setRecordedAudioURL] = useState<string | null>(null);
  const [recordedAudioBase64, setRecordedAudioBlob] = useState<string>('');
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  const getBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data as string);
      };
    });
  };

  const createMediaRecorder = (): MediaRecorder => {
    const mediaRecorder = new MediaRecorder(recordAudioStream);
    mediaRecorder.addEventListener('dataavailable', (event) => {
      if (event.data.size > 0) {
        audioChunksRef.current.push(event.data);
      }
    });

    mediaRecorder.addEventListener('stop', async () => {
      const blob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      const base64 = await getBase64(blob);
      setRecordedAudioBlob(base64);
      const audioURL = URL.createObjectURL(blob);
      setRecordedAudioURL(audioURL);
    });

    return mediaRecorder;
  };

  const startRecording = (): void => {
    setIsRecording(true);
    if (isPaused) {
      mediaRecorderRef.current?.start();
      setIsPaused(false);
      return;
    }
    audioChunksRef.current = [];
    const mediaRecorder = createMediaRecorder();
    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.start();
  };

  const pauseRecording = (): void => {
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
    setIsPaused(true);
  };

  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.removeEventListener('dataavailable', () => {});
        mediaRecorderRef.current.removeEventListener('stop', () => {});
        mediaRecorderRef.current = null;
      }
    };
  }, []);

  return {
    isRecording,
    recordedAudioURL,
    startRecording,
    pauseRecording,
    recordedAudioBase64,
    isPaused,
  };
};

export default useMediaRecorder;

interface MediaRecorderHookResult {
  isRecording: boolean;
  recordedAudioURL: string | null;
  startRecording: () => void;
  pauseRecording: () => void;
  recordedAudioBase64: string;
  isPaused: boolean;
}
