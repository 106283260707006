// Types
import DocumentExtension from './types/DocumentExtension';
// Utils
import textDocuments from './utils/documentFiles';
// Components/ui
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

interface Props {
  documentType: DocumentExtension;
}

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '0.8rem',
  fontWeight: 600,
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.light,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const TextContainer = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  width: '100%',
  padding: '0 3px',
});

const DocumentIcon = ({ documentType }: Props) => {
  const textImageData = textDocuments.find(
    (textDocument) => textDocument.name === documentType
  );

  if (textImageData) {
    return <StyledImage src={textImageData.src} alt="document type" />;
  }

  return (
    <StyledTypography>
      <TextContainer>{documentType?.toUpperCase()}</TextContainer>
    </StyledTypography>
  );
};

export default DocumentIcon;
