import { useEffect, useState, useContext } from 'react';
// Context
import { AudioMessagesContext } from '../../../../../../../../../../context/AudioMessages/AudioMessagesContext';
// Utils
import { getNewWaveSurfer } from '../../../Body/components/Message/components/Body/components/AudioMessage/utils/getNewWaveSurfer';
// Components/ui
import Recorder from './components/Recorder/Recorder';
import { ToggleAudioButton, SoundWave, PauseButton } from './components';
import { Box, IconButton, useTheme } from '@mui/material';
import { Mic, Send, DeleteOutline } from '@mui/icons-material';

type AudioBoxProps = {
  sendAudio: () => Promise<void>;
};

const AudioBox = ({ sendAudio }: AudioBoxProps) => {
  const theme = useTheme();

  const {
    currentTime,
    handleWaveformClick,
    isPlaying,
    isRecording,
    playAudio,
    startRecording,
    stopAudio,
    pauseRecording,
    waveformRef,
    recordedAudioURL,
    formatTime,
    setIsPlaying,
    setCurrentTime,
    handleDeleteRecordedAudio,
    isPaused,
  } = useContext(AudioMessagesContext);

  const [isSendAudio, setIsSendAudio] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);

  const [duration, setDuration] = useState('');

  const handleSendAudio = async () => {
    await sendAudio();
  };

  const handleStopAndSendAudio = () => {
    if (isRecording) {
      pauseRecording();
      setIsSendAudio(true);
    } else {
      handleSendAudio();
    }
  };

  useEffect(() => {
    if (isSendAudio && recordedAudioURL) {
      handleSendAudio();
      return;
    }
    if (waveformRef.current && recordedAudioURL) {
      const progressColor = theme.palette.primary.main;

      const newWaveForm = getNewWaveSurfer('#waveFormId', progressColor);

      waveformRef.current = newWaveForm;
      waveformRef.current.load(recordedAudioURL);
      waveformRef.current.on('ready', () => {
        const newDuration = waveformRef.current.getDuration();
        setDuration(formatTime(newDuration));
        setAudioDuration(newDuration);
      });
      waveformRef.current.on('finish', () => {
        setIsPlaying(false);
      });
      waveformRef.current.on('audioprocess', () => {
        const currentTime = waveformRef.current.getCurrentTime();
        setCurrentTime(formatTime(currentTime));
      });
    }
  }, [recordedAudioURL]);

  useEffect(() => {
    startRecording();
  }, []);

  return (
    <Box
      display="flex"
      position="relative"
      width={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      {/* Control buttons */}
      <Box display={'flex'} gap={1}>
        <IconButton
          disableRipple
          onClick={handleDeleteRecordedAudio}
          sx={{ color: 'text.disabled' }}
        >
          <DeleteOutline />
        </IconButton>
        {isRecording ? (
          <PauseButton handlePauseRecording={pauseRecording} />
        ) : (
          <ToggleAudioButton
            isPlaying={isPlaying}
            playAudio={playAudio}
            stopAudio={stopAudio}
          />
        )}
      </Box>
      {isRecording ? (
        <Recorder isRecording={isRecording} audioDuration={audioDuration} />
      ) : (
        <SoundWave
          currentTime={currentTime}
          duration={duration}
          waveformRef={waveformRef}
          handleWaveformClick={handleWaveformClick}
        />
      )}
      {isPaused && (
        <IconButton
          disableRipple
          onClick={startRecording}
          sx={{ color: 'text.disabled' }}
        >
          <Mic />
        </IconButton>
      )}
      <IconButton
        disableRipple
        onClick={handleStopAndSendAudio}
        sx={{ color: 'text.disabled' }}
      >
        <Send />
      </IconButton>
    </Box>
  );
};

export default AudioBox;
