import axios from 'axios';
// Utils
import getRequestConfig from '../../functions/getRequestConfig';
import ObjectID from 'bson-objectid';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
import { ClientConversation } from './types';
async function fetchFloatingWindowChats(URL: string, jwt: string, uid: string) {
  const config = getRequestConfig.basic(jwt);
  const response = await axios.get<IConversation[]>(
    `${URL}/FloatingWindows/${uid}`,
    config
  );

  return response.data;
}

async function deleteFloatingWindow(
  URL: string,
  jwt: string,
  uid: string,
  data: { chatId: string; conversationId: string }
) {
  const config = getRequestConfig.basic(jwt);

  await axios.delete(`${URL}/FloatingWindows/${uid}`, {
    ...config,
    data,
  });
}

function mapFilesWithId(files: File[], isDocument: boolean) {
  return files.map((file) => ({
    id: ObjectID().toString(),
    url: isDocument ? '' : URL.createObjectURL(file),
    previewUrl: isDocument ? '' : URL.createObjectURL(file),
    mimeType: file.type,
    filename: file.name,
    caption: '',
    file,
  }));
}

function generateClientConversation(
  conversation: IConversation
): ClientConversation {
  return {
    ...conversation,
    open: false,
    inputValue: '',
    recordAudioMode: false,
    files: [],
    documents: [],
    filesDoneLoading: false,
  };
}

export default {
  fetchFloatingWindowChats,
  deleteFloatingWindow,
  mapFilesWithId,
  generateClientConversation,
};
