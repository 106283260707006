import { useState, useEffect, useContext, useRef } from 'react';
// Context
// import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// import { audioMessagesContext } from 'features/Views/Conversations/components/components/Conversation/components/Footer/components/ChatMode/context/AudioMessagesProvider/AudioMessagesProvider';
// Redux
import { useSelector } from 'react-redux';
// Selectors
// import {
//   selectSendMessageStatus,
//   selectMessagesOnError,
//   setMessagesOnError,
// } from 'redux/features/messagesSlice/messagesSlice';
// Hooks
import { useAppDispatch } from '../../../../../../../../../../../../../../hooks/useAppDispatch';
// Components/ui
import {
  Stack,
  Slider,
  IconButton,
  Typography,
  Box,
  useTheme,
  CircularProgress,
} from '@mui/material';
// Icons
import { PlayArrow, Pause } from '@mui/icons-material';
// Components
import { Loader } from './components';
// Utils
import { getNewWaveSurfer } from './utils/getNewWaveSurfer';

const REGEX_BASE64 = /^data:([a-z]+\/[a-z0-9-+.]+);base64/;
const RATE = [1, 1.5, 2, 0.5];

interface AudioMessageProps {
  messageId: string;
  audioUrl: string;
  // handleTryAgainAudio: () => void;
  messageLoading: string[];
}

const AudioMessage = ({
  audioUrl,
  messageId,
  messageLoading,
}: // handleTryAgainAudio,
AudioMessageProps) => {
  const waveformRef = useRef<any | null>(null);
  // const { messageLoading } = useContext(messagesContext);
  // const { durations } = useContext(audioMessagesContext);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState<number>(RATE[0]);
  const [progress, setProgress] = useState(0);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  // const messagesOnError = useSelector(selectMessagesOnError);
  // const uploadAudioStatus = useSelector(selectSendMessageStatus);
  const isLoading = messageLoading.includes(messageId);
  const theme = useTheme();

  useEffect(() => {
    if (waveformRef.current && audioUrl) {
      const progressColor = theme.palette.primary.main;
      const newWaveForm = getNewWaveSurfer(
        `#waveFormId-${messageId}`,
        progressColor
      );

      waveformRef.current = newWaveForm;
      waveformRef.current.load(audioUrl);
      waveformRef.current.on('loading', () => {
        setShowLoader(true);
      });
      waveformRef.current.on('ready', () => {
        const duration = waveformRef.current.getDuration();
        const newCurrentTime = waveformRef.current.getCurrentTime();
        const progressPercentage = (newCurrentTime / duration) * 100;
        setProgress(progressPercentage);
        setDuration(duration);
        setShowLoader(false);
        // const formatDuration = formatTime(duration);
        // durations.push({
        //   id: messageId,
        //   duration: formatDuration,
        // });
      });
      waveformRef.current.on('finish', () => {
        setIsPlaying(false);
        setCurrentTime(0);
        setProgress(0);
        waveformRef.current.stop();
      });
      waveformRef.current.on('audioprocess', () => {
        const newCurrentTime = waveformRef.current.getCurrentTime();
        const newDuration = waveformRef.current.getDuration();
        setCurrentTime(newCurrentTime);
        setProgress(((newCurrentTime + 0.3) / newDuration) * 100);
      });
      waveformRef.current.on('seek', () => {
        const currentTime = waveformRef.current.getCurrentTime();
        setCurrentTime(currentTime);
      });
    }
    return () => {
      if (waveformRef.current && audioUrl) {
        waveformRef.current.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl]);

  const handlePlayPause = () => {
    if (waveformRef.current) {
      if (isPlaying) {
        waveformRef.current.playPause();
        setProgress((currentTime / duration) * 100);
        setIsPlaying(false);
      } else {
        waveformRef.current.playPause();
        setIsPlaying(true);
      }
    }
  };

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handlePlaybackRateChange = () => {
    const rate = RATE[RATE.indexOf(playbackRate) + 1] || RATE[0];
    setPlaybackRate(rate);
    if (waveformRef.current) {
      waveformRef.current.setPlaybackRate(rate);
    }
  };

  const handleChangeValue = (e, newValue: number) => {
    try {
      if (waveformRef.current && !showLoader) {
        const duration = Number(waveformRef.current.getDuration());
        const newTime = (newValue / 100) * duration;
        setProgress(newValue);
        setCurrentTime(newTime);
        setIsPlaying(false);
        waveformRef.current.pause();
        waveformRef.current.seekTo(newTime / duration);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (audioUrl && REGEX_BASE64.test(audioUrl)) {
  //     dispatch(setMessagesOnError(messageId));
  //   }
  // }, [audioUrl]);

  return (
    <Box width="15rem" maxWidth="100%">
      <Stack
        display="flex"
        spacing={2}
        p="0 .5rem"
        direction="row"
        alignItems="center"
      >
        <Box
          sx={{
            width: '3rem',
            height: '3rem',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          {isLoading ? (
            <Loader
              //  handleTryAgain={handleTryAgainAudio}
              isLoading={isLoading}
            />
          ) : showLoader ? (
            <CircularProgress
              size={30}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          ) : (
            <IconButton
              onClick={handlePlayPause}
              size="small"
              sx={{
                color: 'text.disabled',
              }}
            >
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            height: 'max-content',
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Slider
            size="small"
            value={progress}
            onChange={(event, newValue: number) =>
              handleChangeValue(event, newValue)
            }
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              '& .MuiSlider-thumb': {
                width: '0.7rem',
                height: '0.7rem',
              },
              '& .MuiSlider-rail': {
                color: 'transparent',
              },
              '& .MuiSlider-track': {
                color: 'transparent',
              },
              zIndex: 1,
            }}
          />
          <Box
            id={`waveFormId-${messageId}`}
            ref={waveformRef}
            width="100%"
            height="max-content"
            sx={{
              position: 'absolute',
              zIndex: 0,
            }}
          />
        </Box>
        <Typography
          variant="body2"
          fontSize={(theme) => theme.typography.pxToRem(12)}
          display="flex"
          justifyContent="flex-end"
          sx={{
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {currentTime
            ? isPlaying
              ? formatTime(currentTime)
              : formatTime(currentTime)
            : formatTime(duration)}
        </Typography>
        <IconButton
          onClick={() => handlePlaybackRateChange()}
          sx={{
            color: 'text.disabled',
          }}
        >
          <Typography variant="body2" fontSize="small">
            {playbackRate}x
          </Typography>
        </IconButton>
      </Stack>
    </Box>
  );
};

export default AudioMessage;
