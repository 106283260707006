import { Avatar, Box, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/system';
// Types
import { ChatType } from '@trii/types/dist/Conversations';
// Redux
import {
  deleteFloatingWindow,
  openChatItemWindow,
  removeChatById,
  setOpenChatStatus,
} from '../../../../../../../../redux/features/chatsSlice/chatsSlice';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { selectChatById } from '../../../../../../../../redux/features/chatsSlice/selectors';

interface AvatarChatItemProps {
  chatId: string;
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.25rem',
  justifyItems: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: '0.5rem',
  cursor: 'pointer',
  transition: 'background-color 0.15s',
  backgroundColor: 'transparent',
  width: '75px',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  position: 'relative',
  '&:hover .close-button': {
    // Hacer que el botón de cerrar sea visible al hacer hover
    opacity: 1,
  },
}));

const StyledAvatarContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledAvatar = styled(Avatar)({
  width: 30,
  height: 30,
});

const Badge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '-5px',
  right: '20px',
  backgroundColor: theme.palette.error.main,
  // color: theme.palette.text.primary,
  borderRadius: '50%',
  width: '15px',
  height: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '11px',
  fontWeight: 'bold',
  boxShadow: `0 0 4px ${theme.palette.grey[500]}`,
}));

const StyledTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  width: '100%',
});

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  zIndex: 100,
  top: '0px',
  right: '18px',
  width: '11px',
  height: '11px',
  // color: theme.palette.text.primary,
  opacity: 0,
  transition: 'opacity 0.2s',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const AvatarChatItem = ({ chatId }: AvatarChatItemProps) => {
  const dispatch = useAppDispatch();

  const chat = useSelector(selectChatById(chatId));
  const chatName =
    chat.type === ChatType.EXTERNAL ? chat.contactInfo.name : chat.chatName;

  const handleOpenChat = () => {
    dispatch(openChatItemWindow(chat));
  };

  function handleClose() {
    dispatch(setOpenChatStatus({ id: chatId, status: false }));
    dispatch(removeChatById(chatId));

    const data = {
      conversationId: chat.type === ChatType.EXTERNAL ? chatId : '',
      chatId: chat.type === ChatType.EXTERNAL ? '' : chatId,
    };

    dispatch(deleteFloatingWindow(data));
  }

  function handleCloseClick(event: React.MouseEvent) {
    event.stopPropagation();
    handleClose();
  }

  return (
    <StyledBox onClick={handleOpenChat}>
      <CloseButton className="close-button" onClick={handleCloseClick}>
        <Close
          sx={{
            fontSize: '11px',
          }}
        />
      </CloseButton>
      <StyledAvatarContainer>
        <StyledAvatar src={chat.chatImage} />
        {chat.newMessagesCount > 0 && <Badge>{chat.newMessagesCount}</Badge>}
      </StyledAvatarContainer>
      <StyledTypography color="text.primary" variant="caption">
        {chatName}
      </StyledTypography>
    </StyledBox>
  );
};

export default AvatarChatItem;
