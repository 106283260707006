// Types
import { ChatType, IConversation } from '@trii/types/dist/Conversations';
import {
  MessagesGroupedByDate,
  FileType,
  MediaFile,
  GetMessagesData,
} from '../types';
import {
  IMessage,
  MessageAck,
  MessageDirection,
  MessageHeaderType,
  MessageType,
} from '@trii/types/dist/Common/Messages';
// Utils
import emojiRegex from 'emoji-regex';
import moment from 'moment';
import ObjectID from 'bson-objectid';
// Db
import { conversationsDbWorker } from '../../../../db/conversationsDb';
import { chatsDbWorker } from '../../../../db/chatDb';

const FORMAT_DATE = 'DD/MM/YYYY';

export function getMessagesRelationId(data: IConversation) {
  if (data.type === ChatType.EXTERNAL) {
    return data.contactInfo.id;
  } else {
    return `${data.spaceId}_chat_${data.id}`;
  }
}

export function getFileType(type: string): FileType {
  if (type) {
    if (type.startsWith('text/') || type.startsWith('application/')) {
      return 'text';
    } else if (type.startsWith('image/')) {
      return 'image';
    } else if (type.startsWith('video/')) {
      return 'video';
    } else {
      return 'other';
    }
  }
}

export function getFetchMessagesData(
  chatType: ChatType,
  chatId: string,
  id: string
): GetMessagesData {
  if (chatType === ChatType.EXTERNAL) {
    return {
      conversationId: chatId,
      pos: 0,
      contactId: id,
    };
  } else {
    return {
      conversationId: chatId,
      pos: 0,
      shardKey: id,
    };
  }
}

export function getTemplateHeaderFileType(type: MessageHeaderType): FileType {
  switch (type) {
    case MessageHeaderType.IMAGE:
      return 'image';
    case MessageHeaderType.VIDEO:
      return 'video';
    case MessageHeaderType.TEXT:
      return 'text';
    default:
      return 'other';
  }
}

export function Markdown(text: string, addMarkdown: boolean) {
  if (text) {
    const regex =
      /\*\*\s*([^**]+?)\s*\*\*|\*\s*([^*]+?)\s*\*|`([^`]+)`|~~\s*([^~~]+?)\s*~~|__\s*([^__]+?)\s*__/g;
    const regexEmoji = emojiRegex();

    const html = text.replace(
      regex,
      (
        match: string,
        strong: string,
        italic: string,
        code: string,
        strike: string,
        underline: string
      ) => {
        if (strong) {
          const regexLineStart = /\*\*\n/;
          const regexLineEnd = /\n\*\*/;
          const span = !addMarkdown
            ? ''
            : '<span style="color: #888888; ">**</span>';
          return `${span}${
            regexLineStart.test(match) ? (addMarkdown ? '\n' : '') : ''
          }<strong>${strong}</strong>${
            regexLineEnd.test(match) ? (addMarkdown ? '\n' : '') : ''
          }${span}`;
        } else if (italic) {
          const regexLineStart = /\*\n/;
          const regexLineEnd = /\n\*/;
          const span = !addMarkdown ? '' : '<span style="color: #888888; ">*</span>';
          return `${span}${
            regexLineStart.test(match) ? (addMarkdown ? '\n' : '') : ''
          }<em>${italic}</em>${
            regexLineEnd.test(match) ? (addMarkdown ? '\n' : '') : ''
          }${span}`;
        } else if (code) {
          const span = !addMarkdown ? '' : '<span style="color: #888888; ">`</span>';
          return `${span}<code>${code}</code>${span}`;
        } else if (strike) {
          const regexLineStart = /~~\n/;
          const regexLineEnd = /\n~~/;
          const span = !addMarkdown
            ? ''
            : '<span style="color: #888888; ">~~</span>';
          return `${span}${
            regexLineStart.test(match) ? (addMarkdown ? '\n' : '') : ''
          }<s>${strike}</s>${
            regexLineEnd.test(match) ? (addMarkdown ? '\n' : '') : ''
          }${span}`;
        } else if (underline) {
          const regexLineStart = /__\n/;
          const regexLineEnd = /\n__/;
          const span = !addMarkdown
            ? ''
            : '<span style="color: #888888; ">__</span>';
          return `${span}${
            regexLineStart.test(match) ? (addMarkdown ? '\n' : '') : ''
          }<u>${underline}</u>${
            regexLineEnd.test(match) ? (addMarkdown ? '\n' : '') : ''
          }${span}`;
        }
      }
    );

    const modifiedHTML = html.replace(regexEmoji, (match: string) => {
      const unicode = Array.from(match)
        .map((char) => char.codePointAt(0).toString(16))
        .join('-');
      const url = `https://cdn.jsdelivr.net/npm/emoji-datasource-facebook@15.0.1/img/facebook/64/${unicode}.png`;
      return `<span style="background-image: url(${url});" data-lexical-text="true" class="emoji"><span class="children-emoji">${match}</span></span>`;
    });
    return modifiedHTML;
  }
  return '';
}

interface CreateBaseMessageParams {
  spaceInfo: { id: string };
  conversationId: string;
  userInfo: { uid: string };
  channelInfo: any;
  contactInfoId: string | null;
  remoteAddress: string;
  chatType: ChatType;
  timestamp: Date;
  images?: MediaFile[];
  videos?: MediaFile[];
}

export function createBaseMessage({
  spaceInfo,
  conversationId,
  userInfo,
  channelInfo,
  contactInfoId,
  remoteAddress,
  chatType,
  timestamp,
  images,
  videos,
}: CreateBaseMessageParams): IMessage {
  const newMessage: IMessage = {
    id: ObjectID().toString(),
    spaceId: spaceInfo.id,
    conversationId: conversationId,
    timestamp,
    userId: userInfo.uid,
    channelInfo: channelInfo,
    from: contactInfoId || '',
    contactId: contactInfoId || '',
    to: remoteAddress,
    mentions: null,
    direction: MessageDirection.OUT,
    ack: MessageAck.ACK_PENDING,
    ackLogs: [],
    forwarded: false,
    remoteDeleted: false,
    type: MessageType.CHAT,
    text: null,
    audio: null,
    messageReference: null,
    deleted: false,
    isLoaded: true,
    isHighlighted: false,
    shardKey:
      chatType !== ChatType.EXTERNAL
        ? `${spaceInfo.id}_chat_${conversationId}`
        : null,
    images: images || [],
    videos: videos || [],
  };

  return newMessage;
}

export function convertDataUrlToBlob(dataUrl: string) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
}

const REGEX_BASE64 = /^data:([a-z]+\/[a-z0-9-+.]+);base64/;

export function verifyAllLoaded(files: MediaFile[]) {
  return files.every((file) => {
    if (file) {
      const splitBase64 = file.url.split(',')[0];
      const isBase64 = REGEX_BASE64.test(splitBase64);
      return !isBase64;
    }
  });
}
