// Components/ui
import { Chat, PanelButton } from './components';
// Redux
import { useSelector } from 'react-redux';
import { selectOpenChats } from '../../../../redux/features/chatsSlice/selectors';

const Chats = () => {
  const openChats = useSelector(selectOpenChats);

  return (
    <>
      <PanelButton />
      <div
        style={{
          display: 'flex',
          gap: 6,
          right: '2.5rem',
          bottom: '0',
          position: 'absolute',
          alignItems: 'flex-end',
        }}
      >
        {openChats.map((data) => (
          <Chat key={data.id} data={data} />
        ))}
      </div>
    </>
  );
};

export default Chats;
