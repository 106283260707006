import { createContext } from 'react';
import { AudioMessagesContextType } from './types/AudioMessagesContext';

export const AudioMessagesContext = createContext<AudioMessagesContextType>({
  isRecording: false,
  startRecording: () => {},
  pauseRecording: () => {},
  isPlaying: false,
  currentTime: '0:00',
  durations: [],
  setDurations: () => {},
  playAudio: () => {},
  stopAudio: () => {},
  handleWaveformClick: () => {},
  waveformRef: null,
  recordedAudioURL: null,
  formatTime: (time: number) => '',
  setIsPlaying: () => {},
  setCurrentTime: () => {},
  handleDeleteRecordedAudio: () => {},
  recordedAudioBase64: null,
  isPaused: false,
});
